import React from 'react';

const UnsplashBadge = props => {
    //Tomo los props
    const { url, name } = props;
    //Renderizo
    return (
        <a
            style={{ 
                backgroundColor: 'black', 
                color: 'white', 
                textDecoration: 'none', 
                padding: '4px 6px', 
                fontFamily: '-apple-system,BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif', 
                fontSize: '9px', 
                fontWeight: 'bold', 
                lineHeight: '1.2', 
                display: 'inline-block', 
                borderRadius: '3-x' 
            }} 
            href={ url } 
            target="_blank" 
            rel="noopener noreferrer" 
        >
            <span style={{ display:'inline-block', padding:'2px 3px'}}>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    style={{ 
                        height:'8px', 
                        width:'auto', 
                        position: 'relative', 
                        verticalAlign: 'middle', 
                        top:'-2px', 
                        fill:'white'
                    }} 
                    viewBox="0 0 32 32"
                >
                    <title>unsplash-logo</title>
                    <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
                </svg>
            </span>
            <span style={{ display:'inline-block', padding:'2px 3px'}}>{ name }</span>
        </a>
    );
};

export default UnsplashBadge;