import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { isBrowser } from 'react-device-detect';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Breadcrumb from '../layout/Breadcrumb';
import Slider from 'react-slick';
import Loader from '../extras/Loader';
//Axios
import clienteAxios from '../../config/axios';
import ItemAdded from '../cart/ItemAdded';
import { ErrorMessage } from '../helpers/ToastMessages';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { addWishlistAction, addToCartAction } from '../../actions/userActions';
import { MSG_ERROR_SERVER_CONN } from '../../config/messages';


//Función principal
const ProductDetail = () => {
    //Tomo el history
    const history = useHistory();
    //Tomo los parámetros
    const { name } = useParams();
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Tomo del store
    const authUser = useSelector( state => state.user.user );
    //Inicializo el state local
    const [ loading, setLoading ] = useState(true);
    const [ product, setProduct ] = useState({});
    const [ qty, setQty ] = useState(1);
    const [ showAdded, setShowAdded ] = useState(false);
    //Si no hay nombre, debo redireccionar
    if(!name){
        history.push('/');
    }
    useEffect(() => {
        handleProductData();
    }, []);
    //Cargo los datos del producto
    const handleProductData = async () => {
        //Hago la consulta
        try{
            //Obtengo la respuesta
            const response = await clienteAxios.get(`/products/${name}`);
            //Obtengo los datos
            const res = response.data;
            //Seteo los datos
            setProduct(res.data);
            //Inicializo la búsqueda
            setLoading(false);
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Muestro mensaje
            ErrorMessage(data.message);
        }
    }
    //Manejo la cantidad
    const handleChange = e => {
        let v = e.target.value;
        if(v.trim() !== ''){
            //Verifico que sea un número válido
            if(isNaN(v) || v < 1){
                v = 1;
            }
        }
        //Seteo el valor
        setQty(v);
    }
    //Manejo el cambio de cantidad
    const handleQty = increment => {
        //Calculo la nueva cantidad
        const newQty = parseInt(qty) + increment;
        //Me fijo si es válido
        if(newQty >= 1 && newQty !== qty){
            setQty(newQty);
        }
    }
    //Manejo la apertura del success
    const handleOpenAdded = () => {
        setShowAdded(true);
    }
    //Manejo el agregar al carrito
    const handleAddtoCart = e => {
        e.preventDefault();
        //Armo los datos
        const data = {
            product_id: product.id,
            qty
        }
        //Llamo al dispatch
        dispatch( addToCartAction(data, handleOpenAdded) );
    }
    //Manejo de agregar a favoritos
    const handleAddToWishlist = () => {
        if(product){
            //Agrego el producto
            dispatch(addWishlistAction(product));
        }
    }
    //Genero un state para los slider
    let slider1, slider2;
    const [ nav1, setNav1 ] = useState(null);
    const [ nav2, setNav2 ] = useState(null);
    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, []);
    //Slider related
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1430,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    }
    //Slider mini
    const settingsMin = {
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: slider2,
        arrows: false,
        infinite: true,
        dots: false,
        centerMode: false,
        focusOnSelect: true
    }
    //Slider mini mobile
    const settingsMinMobile = {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: slider2,
        arrows: false,
        infinite: true,
        centerMode: false,
        dots: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    }
    //Verifico que properties usar
    let settingsMinUsed = {...settingsMin};
    if(!isBrowser){
        settingsMinUsed = {...settingsMinMobile};
    }
    //Slider product
    const settingsProduct = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: slider1
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                loading ? <Loader /> : (
                    <React.Fragment>
                        <Header />
                        <Breadcrumb title={ product.name} />
                        <section>
                            <div className="collection-wrapper">
                                <div className="container">
                                    <div className="row">
                                        {
                                            product && product.images && product.images.length > 0 ? (
                                                <div className="col-lg-1 col-sm-2 col-xs-12">
                                                    <div className="row">
                                                        <div className="col-12 p-0">
                                                            <Slider { ...settingsMinUsed } className="slider-right-nav" ref={slider => slider1 = slider}>
                                                                {
                                                                    product && product.images && product.images.length > 0 ? (
                                                                        product.images.map(image => (
                                                                            <div key={`it${image.id}`}>
                                                                                <img src={ image.th } alt="" className="img-fluid" />
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div><img src="/assets/img/products/default_900.jpg" alt="" className="img-fluid" /></div>
                                                                    )
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        <div className="col-lg-5 col-sm-10 col-xs-12 order-up">
                                            <Slider { ...settingsProduct } className="product-right-slick" ref={slider => slider2 = slider}>
                                                {
                                                    product && product.images &&  product.images.length > 0 ? (
                                                        product.images.map((image, index) => (
                                                            <div key={`ifd${image.id}`}>
                                                                <img src={ image.img1} alt="" className={`img-fluid image_zoom_cls-${index}`} />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div>
                                                            <img src="/assets/img/products/default_970.jpg" alt="" className="img-fluid image_zoom_cls-0" />
                                                        </div>
                                                    )
                                                }
                                            </Slider>
                                        </div>
                                        <div className="col-lg-6 rtl-text">
                                            <div className="product-right">
                                                <h2>
                                                    { product.name }
                                                    <br/>
                                                    <small>
                                                        { product.brand.name } <small>({ product.category.name })</small>
                                                    </small>
                                                </h2>
                                                {
                                                    product.has_discount ? (
                                                        <h4>
                                                            <del>${ product.original_price }</del>
                                                            <span>{ product.discount }% DESC.</span>
                                                        </h4>
                                                    ) : null
                                                }
                                                
                                                <h3>$ { product.price }</h3>
                                                <div className="product-description border-product">
                                                    <h6 className="product-title">Cantidad</h6>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                            <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-left-minus" data-type="minus" data-field="" onClick={() => handleQty(-1)}>
                                                                    <i className="ti-angle-left"></i>
                                                                </button>
                                                            </span>
                                                            <input 
                                                                type="text" 
                                                                name="qty" 
                                                                className="form-control input-number" 
                                                                value={qty}
                                                                onChange={handleChange} /> 
                                                            <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-right-plus" data-type="plus" data-field="" onClick={() => handleQty(1)}>
                                                                    <i className="ti-angle-right"></i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-buttons">
                                                    <button className="btn btn-solid" onClick={handleAddtoCart}>
                                                        Agregar al carrito
                                                    </button>

                                                    <ItemAdded 
                                                        show={showAdded} 
                                                        setShow={setShowAdded} 
                                                        product={product}
                                                        qty={qty}
                                                    />
                                                </div>
                                                {
                                                    product.description ? (
                                                        <div className="border-product">
                                                            <h6 className="product-title">Descripción</h6>
                                                            <p>{ product.description }</p>
                                                        </div>
                                                    ) : null
                                                }
                                                
                                                <div className="border-product">
                                                    <h6 className="product-title">Compartir</h6>
                                                    <div className="product-icon">
                                                        <ul className="product-social">
                                                            <li>
                                                            {
                                                                <FacebookShareButton
                                                                    quote={product.name}
                                                                    url={ window.location.href }
                                                                    hashtag="#cocinarte"
                                                                >
                                                                    <i className="fa fa-facebook"></i>
                                                                </FacebookShareButton>
                                                            }
                                                            </li>
                                                            <li>
                                                                <TwitterShareButton
                                                                    title={ product.name }
                                                                    hashtags={[
                                                                        product.category.name,
                                                                        'cocinarte'
                                                                    ]}
                                                                    url={ window.location.href }
                                                                >
                                                                    <i className="fa fa-twitter"></i>
                                                                </TwitterShareButton>
                                                            </li>
                                                            <li>
                                                                <WhatsappShareButton
                                                                    title={ product.name }
                                                                    separator=" | "
                                                                    url={ window.location.href }
                                                                >
                                                                    <i className="fa fa-whatsapp"></i>
                                                                </WhatsappShareButton>
                                                            </li>
                                                        </ul>
                                                        {
                                                            authUser ? (
                                                                <form className="d-inline-block">
                                                                    <button className="wishlist-btn" type="button" onClick={handleAddToWishlist} style={{ cursor:'pointer'}}>
                                                                        <i className="fa fa-heart"></i>
                                                                        <span className="title-font">Agregar a favoritos</span>
                                                                    </button>
                                                                </form>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        
                        <Footer />
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default ProductDetail;