import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listBrandsAction } from '../../actions/brandsActions';
import { listCategoriesAction } from '../../actions/categoriesActions';
const PopupFilter = props => {
    //Tomo los props
    const { setBoxSize, setListView, params, setParams, setItemsCount } = props;
    //Inicializo constantes
    const sortItems = [
        {
            name: 'Ordernar productos',
            field: 'id',
            direction: 'desc'
        },
        {
            name: 'Menor precio',
            field: 'price',
            direction: 'asc'
        },
        {
            name: 'Mayor precio',
            field: 'price',
            direction: 'desc'
        },
        {
            name: 'Por nombre',
            field: 'name',
            direction: 'asc'
        }
    ]
    //Inicializo el state local
    const [ showFilter, setShowFilter ] = useState(false);
    const [ isCategoryChecked, setIsCategoryChecked ] = useState({});
    const [ isBrandChecked, setIsBrandChecked ] = useState({});
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de categories
    //const categoriesLoading = useSelector( state => state.categories.loading );
    //const categoriesError = useSelector( state => state.categories.error );
    const categories = useSelector( state => state.categories.categories );
    //Traigo el store de brands
    //const brandsLoading = useSelector( state => state.brands.loading );
    //const brandsError = useSelector( state => state.brands.error );
    const brands = useSelector( state => state.brands.brands );
    //Manejo el show filter
    const handleFilter = e => {
        if(e){
            e.preventDefault();
        }
        //Muestro el filtro
        setShowFilter(!showFilter);
    }
    //Cargo los datos
    useEffect(() => {
        if(!brands){
            dispatch( listBrandsAction() )
        }
           
        if(!categories)
            dispatch( listCategoriesAction() )
    }, [brands, categories, dispatch]);
    //Manejo el cambio de layout
    const handleLayout = (e, items) => {
        e.preventDefault();
        const size = 12 / items;
        setBoxSize(`col-xl-${size}`);
    }
    //Manejo el cambio de vista
    const handleView = (isListView) => {
        setListView(isListView);
    }
    //Manejo del cambio de limite
    const handleLimit = e => {
        //Seteo el tamaño de página
        setItemsCount(parseInt(e.target.value));
        //Seteo los nuevos parámetros
        setParams({
            ...params,
            limit: e.target.value
        })
    }
    //Manejo del cambio de orden
    const handleSort = e => {
        const item = sortItems[e.target.value];
        setParams({
            ...params,
            sort_by: item.field,
            direction: item.direction
        })
    }
    //Manejo el filtro por categoría
    const handleCategory = e => {
        //Genero copia de params
        let paramsCopy = {...params};
        //Deselecciono todo
        setIsCategoryChecked({});
        //Me fijo si es chequear
        if(e.target.checked){
            //Agrego el valor
            paramsCopy = {
                ...paramsCopy,
                category: e.target.value
            }
            //Seteo el check
            setIsCategoryChecked({
                [e.target.value]: true
            });
        }else{
            //Elimino el filtro
            delete paramsCopy.category;
        }
        //Asigno los datos
        setParams({
            ...paramsCopy
        })
        //Cierro el filtro
        handleFilter(false);
    }
    //Manejo el filtro por marca
    const handleBrand = e => {
        //Genero copia de params
        let paramsCopy = {...params};
        //Deselecciono todo
        setIsBrandChecked({});
        //Me fijo si es chequear
        if(e.target.checked){
            //Agrego el valor
            paramsCopy = {
                ...paramsCopy,
                brand: e.target.value
            }
            //Seteo el check
            setIsBrandChecked({
                [e.target.value]: true
            });
        }else{
            //Elimino el filtro
            delete paramsCopy.brand;
        }
        //Asigno los datos
        setParams({
            ...paramsCopy
        })
        //Cierro el filtro
        handleFilter(false);
    }
    //Renderizo
    return (
        <div className="popup-filter">
            <div className="sidebar-popup" onClick={handleFilter}>
                <a className="popup-btn" href="!#" onClick={ isMobile ? handleFilter : undefined}>Filtrar productos</a>
            </div>
            <div className="open-popup" style={ showFilter ? { display: 'block'} : null }>
                <div className="collection-filter" style={ isMobile ? {left: `${showFilter ? '-15px' : '-365px'}`} : null }>
                    <div className="collection-filter-block">
                        <div className="collection-mobile-back" onClick={isMobile ? handleFilter : undefined}>
                            <span className="filter-back"><i className="fa fa-angle-left" aria-hidden="true"></i> Atrás</span>
                        </div>
                        
                        <Accordion className="collection-collapse-block" defaultActiveKey="0" style={ isMobile && showFilter ? {width: '200px'} : null}>
                            <Accordion.Toggle as="h3" className="collapse-block-title" eventKey="0">
                                Categorías
                            </Accordion.Toggle>
                            <Accordion.Collapse className="collection-collapse-block-content" eventKey="0">
                                <div className="collection-brand-filter">
                                    {
                                        categories ? (
                                            categories.map(category => (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={`pfc${category.id}`}>
                                                    <input 
                                                        type="checkbox" 
                                                        className="custom-control-input" 
                                                        id={`category-${category.id}`} 
                                                        onChange={handleCategory} 
                                                        value={category.id} 
                                                        checked={isCategoryChecked[category.id]}
                                                        />
                                                    <label className="custom-control-label" htmlFor={`category-${category.id}`}>
                                                        { category.name }
                                                    </label>
                                                </div>
                                            ))
                                        ) : null
                                    }
                                </div>
                            </Accordion.Collapse>
                        </Accordion>

                        <Accordion className="collection-collapse-block" defaultActiveKey="1" style={ isMobile && showFilter ? {width: '200px'} : null}>
                            <Accordion.Toggle as="h3" className="collapse-block-title" eventKey="1">
                                Marcas
                            </Accordion.Toggle>
                            <Accordion.Collapse className="collection-collapse-block-content" eventKey="1">
                                <div className="collection-brand-filter">
                                    {
                                        brands ? (
                                            brands.map(brand => (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={`pfb${brand.id}`}>
                                                    <input 
                                                        type="checkbox" 
                                                        className="custom-control-input" 
                                                        id={`brand-${brand.id}`}
                                                        onClick={handleBrand}
                                                        value={brand.id}
                                                        checked={isBrandChecked[brand.id]}
                                                        />
                                                    <label className="custom-control-label" htmlFor={`brand-${brand.id}`}>
                                                        { brand.name }
                                                    </label>
                                                </div>
                                            ))
                                        ) : null
                                    }
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                </div>
            </div>
            <div className="collection-view">
                <ul>
                    <li onClick={() => handleView(false)}><i className="fa fa-th grid-layout-view"></i></li>
                    <li onClick={() => handleView(true)}><i className="fa fa-list-ul list-layout-view"></i></li>
                </ul>
            </div>
            <div className="collection-grid-view">
                <ul>
                    <li>
                        <a href="!#" className="product-2-layout-view" onClick={e => handleLayout(e, 2)}>
                            <ul className="filter-select">
                                <li></li>
                                <li></li>
                            </ul>
                        </a>
                    </li>
                    <li>
                        <a href="!#" className="product-3-layout-view" onClick={e => handleLayout(e, 3)}>
                            <ul className="filter-select"><li></li><li></li><li></li></ul>
                        </a>
                    </li>
                    <li>
                        <a href="!#" className="product-4-layout-view" onClick={e => handleLayout(e, 4)}>
                            <ul className="filter-select"><li></li><li></li><li></li><li></li></ul>
                        </a>
                    </li>
                    <li>
                        <a href="!#" className="product-6-layout-view" onClick={e => handleLayout(e, 6)}>
                            <ul className="filter-select"><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="product-page-per-view">
                <select name="limit" onChange={handleLimit}>
                    <option value="24">24 Productos por página</option>
                    <option value="50">50 Productos por página</option>
                    <option value="100">100 Productos por página</option>
                </select>
            </div>
            <div className="product-page-filter">
                <select name="sort_by" onChange={handleSort}>
                    {
                        sortItems.map((item, index) => (
                            <option value={index} key={`si${index}`}>
                                { item.name }
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    );
};

export default PopupFilter;