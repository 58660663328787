import React, { useEffect, useState } from 'react';
import BoxHome from '../products/BoxHome';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listProductsAction } from '../../actions/productsActions';
//Función principal
const LastProducts = () => {
    //Limite de productos en home
    const limit = 18;
    //Inicializo el state local
    const [ tab, setTab ] = useState(1);
    const [ params, setParams ] = useState({
        limit
    });
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de productos
    const productsLoading = useSelector( state => state.products.loading );
    const products = useSelector( state => state.products.products );
    //Cargo los datos
    useEffect(() => {
        //Al inicio traigo los últimos
        dispatch( listProductsAction(params) )
    }, [params, dispatch]);
    //Manejo el cambio de pestaña
    const handleTabs = (e, option) => {
        e.preventDefault();
        //Seteo el tab
        setTab(option);
        switch(option){
            case 1:
                setParams({
                    limit,
                    new: 1
                })
                break;
            case 2:
                setParams({
                    limit,
                    promotion: 1
                })
                break;
            case 3:
                setParams({
                    limit,
                    featured: 1
                })
                break;
            default:
                return false;
        }
    }
    //Renderizo
    return (
        <section className=" tab-layout1 section-b-space">
            <div className="theme-tab">
                <ul className="tabs border-top-0">
                    <li className={tab === 1 ? 'current' : null}>
                        <a href="!#" onClick={e => handleTabs(e, 1)}>Últimos productos</a>
                    </li>
                    <li className={tab === 2 ? 'current' : null}>
                        <a href="!#" onClick={e => handleTabs(e, 2)}>En oferta</a>
                    </li>
                    <li className={tab === 3 ? 'current' : null}>
                        <a href="!#" onClick={e => handleTabs(e, 3)}>Destacados</a>
                    </li>
                </ul>
                <div className="tab-content-cls ratio_square">
                    <div id="tab-1" className="tab-content active default" style={{ display: 'block' }}>
                        <div className="container shadow-cls">
                            <div className="drop-shadow">
                                <div className="left-shadow">
                                    <img src="../assets/images/left.png" alt="" className="img-fluid" />
                                </div>
                                <div className="right-shadow">
                                    <img src="../assets/images/right.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="row border-row1 m-0">
                                {
                                    productsLoading ? (
                                        <div className="col-12 mb-2 text-center">
                                            <h4>Cargando...</h4>
                                        </div>
                                    ) : (
                                        products && products.length > 0 ? (
                                            products.map(product => (
                                                <BoxHome key={`pbh${product.id}`} product={product} />
                                            ))
                                        ) : (
                                            <div className="col-12 mb-2 text-center">
                                                <h4>Por el momento no hay productos relacionados a esta categoría...</h4>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LastProducts;