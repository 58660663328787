import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
import { useSelector } from 'react-redux';

const Faq = () => {
    const freeShipping = useSelector( state => state.freeShipping.limit );

    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="Preguntas frecuentes" />
            <section class="faq-section section-b-space">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="accordion theme-accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0"><button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Adonde se realizan envíos?</button></h5></div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Los envíos a domicilio son únicamente para las localidades de San Miguel de Tucumán, Yerba Buena y Tafí Viejo. En caso de pertenecer a otra localidad y/o provincia por favor contactáte con nosotros desde <Link to="/contacto"><strong>aquí.</strong></Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Cuando los envíos son gratis?</button></h5></div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Los envíos a domicilio serán bonificados (gratis) cuando la compra a realizar sea igual o mayor a los ${ freeShipping ? freeShipping : '-' }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Que días se realizan los envíos?</button></h5></div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Los envíos a domicilio se realizan los días lunes, miércoles y viernes por la mañana.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h5 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Envíos a domicilio sin frío?</button></h5></div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Los envíos a domicilio son únicamente para productos que no requieren mantener su cadena de frío. De cualquier forma puede consultarnos a través de <i className="fa fa-whatsapp"></i> <strong>{ process.env.REACT_APP_CONTACT }</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h5 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Cuáles son los medios de pago?</button></h5></div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Podés pagar con cualquier de tus tarjetas (débito y crédito), y las diferentes medios, que permite MercadoPago. Además, podes optar por el pago en efectivo directamente al retirar y/o recibir el producto en tu domicilio.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingSix">
                                        <h5 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Qué ocurre si tengo un problema con mi compra?</button></h5></div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Por cualquier inconveniente al realizar tu compra, podés contactarnos a través de nuestro mail de contacto <strong><a href="mailto:info@cocinarte-mercadito.com.ar">info@cocinarte-mercadito.com.ar</a></strong> o través de Whatsapp al <a href={`https://api.whatsapp.com/send?phone=+549${process.env.REACT_APP_CONTACT}`} target="_blank" rel="noopener noreferrer"><strong>{ process.env.REACT_APP_CONTACT }</strong></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </React.Fragment>
    );
};

export default Faq;