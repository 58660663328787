export const STORE_PAYMENT = 1;
export const ONLINE_PAYMENT = 2;
export const TRANSFER_PAYMENT = 3;

export const STORE_PAYMENT_NAME = 'store';
export const ONLINE_PAYMENT_NAME = 'online';
export const TRANSFER_PAYMENT_NAME = 'transfer';

export default {
    [STORE_PAYMENT]: STORE_PAYMENT_NAME,
    [ONLINE_PAYMENT]: ONLINE_PAYMENT_NAME,
    [TRANSFER_PAYMENT]: TRANSFER_PAYMENT_NAME
}