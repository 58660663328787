import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { Button } from 'react-bootstrap';
import Breadcrumb from '../layout/Breadcrumb';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import PopupFilter from './PopupFilter';
import BoxMarket from './BoxMarket';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listProductsAction } from '../../actions/productsActions';

//Función principal
const Products = () => {
    //Traigo los parámetros
    const { query } = useParams();
    //Traigo el location
    const location = useLocation();
    //Traigo los parámetros de búsqueda
    const searchParams = new URLSearchParams(location.search);
    //Constantes iniciales
    const initialLimit = 24;
    const initialBoxSize = 'col-xl-3';
    //Inicializo el state local
    const [ itemsCount, setItemsCount ] = useState(initialLimit);
    const [ listView, setListView ] = useState(false);
    const [ boxSize, setBoxSize ] = useState(initialBoxSize);
    const [ isSearch, setIsSearch ] = useState(query === 'search' && searchParams.has('name'));
    const [ params, setParams ] = useState(query === 'search' ? {
        limit: initialLimit,
        name: searchParams.has('name') ? searchParams.get('name') : '',
        category: searchParams.has('category') ? searchParams.get('category') : ''
    } : {
        limit: initialLimit
    });
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de productos
    const productsLoading = useSelector( state => state.products.loading );
    //const productsError = useSelector( state => state.products.error );
    const products = useSelector( state => state.products.products );
    //Cargo los datos
    useEffect(() => {
        //Al inicio traigo los últimos, sólo si no es consulta
        dispatch( listProductsAction(params) )
    }, [params, dispatch]);
    //Cambio de vista
    useEffect(() => {
        setBoxSize(listView ? 'col-xl-12' : initialBoxSize);
    }, [listView]);
    //Veo si cambian los parámetros de búsqueda
    useEffect(() => {
        if(query === 'search'){
            //Me fijo si hay algun parámetro de búsqueda
            if(searchParams.has('name') || searchParams.has('category')){
                //Si no está cargando
                if(!productsLoading){
                    setParams({
                        limit: initialLimit,
                        name: searchParams.has('name') ? searchParams.get('name') : '',
                        category: searchParams.has('category') ? searchParams.get('category') : ''
                    })
                }
            }
        }
    }, [location]);
    //Función para cargar más
    const handleLoadMore = e => {
        e.preventDefault();
        //Asigno la cantidad
        // setItemsCount(products.length);
        //Agrego items a lo actual
        const newLimit = parseInt(params.limit) + parseInt(itemsCount);
        //Seteo los parámetros
        setParams({
            ...params,
            limit: newLimit
        })
    }
    //Handle para eliminar filtro
    const handleRemoveFilter = () => {
        //Copio los filtros
        const paramsCopy = {
            ...params
        }
        //Remuevo el filtro por nombre
        delete paramsCopy.name;
        //Seteo los nuevos parámetors
        setParams(paramsCopy);
        //Quito la búsqueda
        setIsSearch(false);
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="Mercadito" />

            <section className="section-b-space ratio_square pt-0">
                <div className="collection-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="collection-content col">
                                <div className="page-main-content">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="top-banner-wrapper">
                                                <div className="top-banner-content small-section">
                                                    <h5>Bienvenido a nuestro mercadito, encontrá una amplia variedad de alimentos saludables.</h5>
                                                    <p>Mientras, aquí estoy para guiarlos y acompañarlos en un camino hacia un estilo de vida saludable, de a poco les sugiero ir reemplazando ciertos productos por otros más naturales, pequeños cambios que se convierten en grandes beneficios para nuestro organismo.</p>
                                                </div>
                                            </div>
                                            <div className="collection-product-wrapper">
                                                <div className="product-top-filter">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <PopupFilter 
                                                                setBoxSize={setBoxSize}
                                                                setListView={setListView}
                                                                params={params}
                                                                setParams={setParams}
                                                                setItemsCount={setItemsCount}
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    isSearch ? (
                                                        <div className="row">
                                                            <div className="col-md-12 mt-4">
                                                                <i>Se muestran resultados para: </i>
                                                                <Button size="sm" variant="outline-secondary" onClick={handleRemoveFilter}>
                                                                    <i className="fa fa-times"></i> { searchParams.get('name') }
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    products ? (
                                                        <React.Fragment>
                                                            <div className={`product-wrapper-grid ${listView && 'list-view'}`}>
                                                                <div className="row">
                                                                    {
                                                                        productsLoading ? (
                                                                            <div className="col-12">
                                                                                <img src="/assets/images/ajax-loader.gif" alt="Cargando..." />
                                                                            </div>
                                                                        ) : (
                                                                            products.map(product => (
                                                                                <BoxMarket 
                                                                                    key={`pbm${product.id}`} 
                                                                                    product={product}
                                                                                    boxSize={boxSize}
                                                                                    listView={listView}
                                                                                    />
                                                                            ))
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                !productsLoading && products.length >= params.limit ? (
                                                                    <div className="load-more-sec">
                                                                        <a href="!#" className="loadMore" onClick={handleLoadMore}>Cargar más productos</a>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </React.Fragment>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </React.Fragment>
    );
};

export default Products;