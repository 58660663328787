import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import LeftNav from './LeftNav';
import MainMenu from './MainMenu';
import WishlistPanel from '../cart/WishlistPanel';
import LoginPanel from '../user/LoginPanel';
import CartPanel from '../cart/CartPanel';
import FormSearch from './FormSearch';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataAction, logoutAction, getCartAction } from '../../actions/userActions';
import { getFreeShippingLimitAction } from '../../actions/freeShippingLImitActions';

import Cookie from '../../services/CookieService';
import { COOKIE_ACCESS_TOKEN } from '../../config/cookies';

//Función principal
const Header = () => {
    //Traigo el history
    const history = useHistory();
    //Inicializo el state local
    const [ showCart, setShowCart ] = useState(false);
    const [ showWishlist, setShowWishlist ] = useState(false);
    const [ showLogin, setShowLogin ] = useState(false);
    const [ showUserMenu, setShowUserMenu ] = useState(false);
    const [ showSearchMobile, setShowSearchMobile ] = useState(false);
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store
    const authUser = useSelector( state => state.user.user );
    //Traigo el store de carrito
    const cartWishlist = useSelector( state => state.user.wishlist );
    const cart = useSelector( state => state.user.cart );
    const freeShipping = useSelector( state => state.freeShipping.limit );
    //Traigo el token
    const token = Cookie.get(COOKIE_ACCESS_TOKEN);
    //Cambios
    useEffect(() => {
        //Sólo voy a buscar si hay token
        if(token){
            //Busco los datos del usuario
            dispatch( getUserDataAction(authUser) );
        }else{
            //Sólo busco datos del carro
            dispatch( getCartAction() );
        }
    }, []);

    useEffect(() => {
        getFreeShippingCost();
    }, []);

    const getFreeShippingCost = () => {
        dispatch( getFreeShippingLimitAction() );
    }

    //Manejo de carrito
    const handleCart = e => {
        e.preventDefault();
        setShowCart(!showCart);
    }
    //Manejo de wishlist
    const handleWishlist = e => {
        e.preventDefault();
        setShowWishlist(!showWishlist);
    }
    //Manejo de login
    const handleLogin = e => {
        e.preventDefault();
        setShowLogin(!showLogin);
    }
    //Manejo el menu de usuario
    const handleUserMenu = e => {
        e.preventDefault();
        setShowUserMenu(!showUserMenu);
    }
    //Manejo la redirección del menu de usuario
    const handleUserMenuLink = r => {
        history.push(r);
    }
    //Manejo el cierre de sesión
    const handleLogout = () => {
        dispatch( logoutAction() );
    }
    //Manejo la apertura y cierre del form mobile
    const handleFormMobile = () => {
        if(isMobile){
            setShowSearchMobile(!showSearchMobile);
        }
    }
    //Renderizo
    return (
        <header className="header-1">
            <div className="mobile-fix-header">
            </div>
            <div className="container">
                <div className="row header-content">
                    <div className="col-lg-3 col-6">
                        <div className="left-part">
                            <p>Envío gratis a partir de ${ freeShipping ? freeShipping : '-' }</p>
                        </div>
                    </div>
                    <div className="col-lg-9 col-6">
                        <div className="right-part">
                            <ul>
                            <li>
                                    <a href={ process.env.REACT_APP_LINK_RECETAS } rel="noopener noreferrer" target="_blank">
                                        Cocinarte Estudio (Recetas)
                                    </a>
                                </li>
                                <li>
                                {
                                    authUser ? (
                                        <div className={`dropdown ${showUserMenu ? 'active' : ''}`}>
                                            <div className="select" onClick={handleUserMenu}>
                                                <span style={{ textTransform: 'lowercase' }}>{ authUser.user.email }</span>
                                            </div>
                                            <ul className="dropdown-menu" style={{ display: showUserMenu ? 'block' : 'none' }}>
                                                <li onClick={() => handleUserMenuLink('/perfil')}>
                                                    <i className="fa fa-user"></i> Mi Perfil
                                                </li>
                                                <li onClick={() => handleUserMenuLink('/mis-pedidos')}>
                                                    <i className="fa fa-bars"></i> Mis Pedidos
                                                </li>
                                                <li onClick={() => handleLogout()}>
                                                    <i className="fa fa-sign-out"></i> Salir
                                                </li>
                                            </ul>
                                        </div>
                                    ) : (
                                        <Link to="/iniciar-sesion">
                                            Iniciar sesión / Registrarse
                                        </Link>
                                    )
                                }
                                </li>
                                <li>
                                    
                                    
                                </li>
                                <li>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row header-content">
                    <div className="col-12">
                        <div className="header-section">
                            <LeftNav />

                            <div className="brand-logo">
                                <Link to="/">
                                    <img src="/assets/img/logo/logo-header.png" className="" alt="" width="215" />
                                </Link>
                            </div>

                            <div className="search-bar">
                                <FormSearch />
                            </div>

                            <div className="nav-icon">

                                <LoginPanel handleLogin={handleLogin} showLogin={showLogin} />
                                <WishlistPanel handleWishlist={handleWishlist} showWishlist={showWishlist} />
                                <CartPanel handleCart={handleCart} showCart={showCart} />

                                <ul>
                                    <li className="onhover-div search-3">
                                        <div onClick={handleFormMobile}>
                                            <i className="ti-search mobile-icon-search" ></i>
                                            <img src="/assets/images/icon/l-1/search.png" className=" img-fluid search-img" alt="" />
                                        </div>
                                        <div id="search-overlay" className="search-overlay" style={ isMobile && showSearchMobile ? {display: 'block'} : {display: 'none'} }>
                                            <div>
                                                <span className="closebtn" onClick={handleFormMobile} title="Cerrar">×</span>
                                                <div className="overlay-content">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-xl-12">
                                                                <FormSearch />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        authUser ? (
                                            <li className="onhover-div wishlist-icon" onClick={handleWishlist}>
                                                <img src="/assets/images/icon/l-2/wishlist.png" alt="" className="wishlist-img" />
                                                <i className="ti-heart mobile-icon"></i>
                                                <div className="wishlist icon-detail">
                                                    <h6 className="up-cls">
                                                        <span>{ cartWishlist ? cartWishlist.length : '0'} item</span>
                                                    </h6>
                                                    <h6><Link to="/favoritos">Favoritos</Link></h6>
                                                </div>
                                            </li>
                                        ) : null
                                    }
                                    <li className="onhover-div user-icon" onClick={handleLogin}>
                                        <img src="/assets/images/icon/l-2/user.png" alt="" className="user-img" />
                                        <i className="ti-user mobile-icon"></i>
                                        <div className="wishlist icon-detail">
                                            <h6 className="up-cls"><span>Mi cuenta</span></h6>
                                            <h6>
                                                {
                                                    authUser ? (
                                                        <Link to="/perfil">
                                                            { authUser.account.first_name }
                                                        </Link>
                                                    ) : (
                                                        <Link to="/iniciar-sesion">ingreso/registro</Link>
                                                    )
                                                }
                                                
                                            </h6>
                                        </div>
                                    </li>
                                    <li className="onhover-div cart-icon" onClick={handleCart}>
                                        <img src="/assets/images/icon/l-2/cart.png" alt="" className="cart-image" />
                                        <i className="ti-shopping-cart mobile-icon"></i>
                                        <div className="cart  icon-detail">
                                            <h6 className="up-cls">
                                                <span>{ cart ? cart.items_count : '0'} Item/s</span>
                                            </h6>
                                            <h6><Link to="/carrito">Mi carro</Link></h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-class">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <MainMenu />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;