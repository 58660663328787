import React, { useState, useRef } from 'react';
import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
import { Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';
//Mensajes
import { SuccessMessage } from '../helpers/ToastMessages';
//Axios
import clienteAxios from '../../config/axios';
//Función principal
const ContactUs = () => {
    //Genero los refs
    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();
    //Inicializo los datos
    const initialData = {
        name: '',
        phone: '',
        email: '',
        message: ''
    }
    //Inicializo el state local
    const [ data, setData ] = useState(initialData);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState(false);
    //Tomo los cambios
    const onChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    //Manejo el submit
    const handleSubmit = async e => {
        e.preventDefault();
        //Reinicio el error
        setMessage(null);
        //Pongo la espera
        setLoading(true);
        Swal.fire({
            title: 'Enviando mensaje',
            text: 'Por favor aguarde.',
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Capturo error
        try{
            //Envío el mensaje
            await clienteAxios.post('/contact/send', data);
            //Verifico la respuesta
            /*setMessage({
                type: 'success',
                text: response.data.message
            })*/
            //Limipio los valores
            setData(initialData);
            //Quito la espera
            setLoading(false);
            //Oculto
            Swal.close();
            //Muestro toast
            SuccessMessage('Gracias por contactarse. Le responderemos a la brevedad.');
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: 'Error al conectarse al servidor'
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Muestro mensaje de error
            setMessage({
                type: 'danger',
                text: data.message
            })
            //Quito la espesra
            setLoading(false);
            //Oculto
            Swal.close();
        }
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="Contacto" />
            <section className="contact-page section-b-space pb-0">
                <div className="container">
                    <div className="row section-b-space">
                        <div className="col-lg-7 map">
                            <form className="theme-form" onSubmit={handleSubmit} autoComplete="off">
                                <div className="form-row">
                                    {
                                        message ? (
                                            <div className="col-md-12">
                                                <Alert variant={ message.type }>
                                                    { message.text}
                                                </Alert>
                                            </div>
                                        ) : null
                                    }
                                    <div className="col-md-12">
                                        <label htmlFor="name">Nombre</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="name" 
                                            placeholder="Ingrese su nombre" 
                                            required 
                                            ref={nameRef}
                                            value={data.name}
                                            onChange={onChange} />
                                    </div>

                                    <div className="col-md-12">
                                        <label htmlFor="review">Teléfono</label>
                                        <input 
                                            type="text" 
                                            className="form-control"  
                                            placeholder="Ingrese su teléfono"
                                            name="phone"
                                            value={data.phone}
                                            onChange={onChange} />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="email">Email</label>
                                        <input 
                                            type="text" 
                                            className="form-control"  
                                            placeholder="Email" 
                                            name="email"
                                            required 
                                            ref={emailRef}
                                            value={data.email}
                                            onChange={onChange} />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="review">Escriba su mensaje</label>
                                        <textarea 
                                            className="form-control" 
                                            placeholder="Escriba su mensaje" 
                                            rows="6" 
                                            ref={messageRef} 
                                            onChange={onChange}
                                            name="message"
                                            value={data.message}
                                            />
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn btn-solid" type="submit" disabled={loading}
                                            >Enviar mensaje
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-right">
                                <ul>
                                    <li>
                                        <div className="contact-icon"><i className="fa fa-phone" aria-hidden="true"></i>
                                            <h6>Whatsapp</h6></div>
                                        <div className="media-body">
                                            <p>+54 381 6735590 </p>
                                            <p><small>Sólo mensajes</small></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-icon"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <h6>Ubicación</h6></div>
                                        <div className="media-body">
                                            <p>Yerba Buena, Tucumán</p>
                                            <p>C.P. 4107</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-icon"><i className="fa fa-envelope-o" aria-hidden="true"></i>
                                            <h6>Email</h6></div>
                                        <div className="media-body">
                                            <p>info@cocinarte-mercadito.com.ar</p>
                                            <p><small>Envíanos tu consulta</small></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </section>

            <Footer />
        </React.Fragment>
    );
};

export default ContactUs;