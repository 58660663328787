import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
import Loader from '../extras/Loader';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getCartAction, addToCartAction, removeFromCartAction } from '../../actions/userActions';

//Función principal
const Cart = () => {
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de carrito
    const cart = useSelector( state => state.user.cart );
    const cartLoading = useSelector( state => state.user.cartLoading );
    //Al inicio cargo los datos
    useEffect(() => {
        if(!cart){
            dispatch( getCartAction() );
        }
    }, []);
    //Manejo los cambios
    const handleChange = (e, item) => {
        let v = e.target.value;
        if(v.trim() === ''){
            //Si el valor es vacío, salgo
            return false;
        }
        //Verifico que sea un número válido
        if(isNaN(v) || v < 1){
            v = 1;
            e.target.value = v;
        }
        //Cálculo la diferencia
        let newQty = v - item.qty;
        //Genero los datos a enviar
        const data = {
            product_id: item.product.id,
            qty: Math.abs(newQty)
        }
        //Si es positivo, debo agregar
        if(newQty > 0){
            //Agrego la cantidad
            dispatch( addToCartAction(data) );
        }else if(newQty < 0){
            //Quito la cantidad
            dispatch( removeFromCartAction(data) );
        }
    }
    //Manejo el quitar del carro
    const handleRemove = (e, item) => {
        e.preventDefault();
        //Genero los datos a enviar
        const data = {
            product_id: item.product.id,
            qty: item.qty
        }
        //Quito la cantidad
        dispatch( removeFromCartAction(data) );
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                !cart ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <Header />
                        <Breadcrumb title="Mi carrito" />
                        
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className="table cart-table table-responsive-xs striped-table">
                                            <thead>
                                            <tr className="table-head">
                                                <th scope="col">Imagen</th>
                                                <th scope="col">Producto</th>
                                                <th scope="col">Precio</th>
                                                <th scope="col">Cantidad</th>
                                                <th scope="col">Quitar</th>
                                                <th scope="col">total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cart && cart.items ? (
                                                        cart.items.map(item => (
                                                            <tr 
                                                                key={`ci${item.id}`}
                                                                className={`${item.check_item === 1 ? 'table-danger' : ''}`}
                                                            >
                                                                <td>
                                                                <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                                    {
                                                                        item.product && item.product.images.length > 0 ? (
                                                                            <img src={ item.product.images[0].img1 } alt="" />
                                                                        ) : (
                                                                            <img src="/assets/img/products/default_970.jpg" alt="" />
                                                                        )
                                                                    }
                                                                </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                                        { item.name }
                                                                    </Link>
                                                                    <div className="mobile-cart-content row">
                                                                        <div className="col-xs-3">
                                                                            <div className="qty-box">
                                                                                <div className="input-group">
                                                                                    <input 
                                                                                        type="text" 
                                                                                        name="quantity" 
                                                                                        className="form-control input-number" 
                                                                                        value={ item.qty }
                                                                                        onChange={e => handleChange(e, item)}
                                                                                        disabled={cartLoading}
                                                                                        />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xs-3">
                                                                            <h2 className="td-color">$ { item.price }</h2>
                                                                        </div>
                                                                        <div className="col-xs-3">
                                                                            <h2 className="td-color">
                                                                                <a href="!#" className="icon" onClick={e => handleRemove(e, item)}>
                                                                                    <i className="ti-close"></i>
                                                                                </a>
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h2>$ { item.price }</h2>
                                                                </td>
                                                                <td>
                                                                    <div className="qty-box">
                                                                        <div className="input-group">
                                                                            <input 
                                                                                type="number" 
                                                                                name="quantity" 
                                                                                className="form-control input-number" 
                                                                                value={item.qty}
                                                                                onChange={e => handleChange(e, item)}
                                                                                disabled={cartLoading} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="!#" className="icon" onClick={e => handleRemove(e, item)}>
                                                                        <i className="ti-close"></i>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <h2 className="td-color">{ `${cart.cart_currency_code} ${item.total}` }</h2>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : null
                                                }
                                            </tbody>
                                        </table>
                                        <table className="table cart-table table-responsive-md">
                                            <tfoot>
                                            <tr>
                                                <td>Total:</td>
                                                <td>
                                                    <h2>{ cart.cart_currency_code }{ cart ? cart.subtotal : '0.00' }</h2>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="row cart-buttons">
                                    <div className="col-12 col-md-6">
                                        <Link to="/mercadito/productos/todos" className="btn btn-solid btn-block">
                                            Seguir comprando
                                        </Link>
                                    </div>
                                    <div className="col-12 col-md-6">
                                    {
                                        cart && cart.items && cart.items.length > 0 ? (
                                            <Link to="/carrito/checkout" className="btn btn-solid btn-green btn-block mt-2 mt-md-0">
                                                Finalizar compra
                                            </Link>
                                        ) : null
                                    }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default Cart;