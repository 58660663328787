import React from 'react';

const ShipmentBanner = () => {
    return (
        <section className="full-banner" >
            <div className="info-shipment">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="info-content">
                                <h4> <i className="fa fa-archive" aria-hidden="true"></i>Entregas en Domicilio</h4>
                                <p>Recordá que nuestros envíos a domicilio se realizan de <b>Lunes a Viernes</b> por la mañana u horario a convenir por Whatsapp.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ShipmentBanner;