import { ErrorMessage } from "../components/helpers/ToastMessages";

export const validateUser = (user, validatePass = false) => {
    //Hago la validación
    if(!user.first_name || user.first_name.trim() === ''){
        ErrorMessage('Debe indicar el nombre');
        //refName.current.focus();
        return {
            success: false,
            field: 'first_name'
        }
    }
    //Apellido
    if(!user.last_name || user.last_name.trim() === ''){
        ErrorMessage('Debe indicar el apellido');
        //refLastname.current.focus();
        return {
            success: false,
            field: 'last_name'
        }
    }
    //Email
    if(!user.email || user.email.trim() === ''){
        ErrorMessage('Debe indicar el Email');
        //refEmail.current.focus();
        return {
            success: false,
            field: 'email'
        }
    }
    //Password
    if(validatePass){
        if(!user.password || user.password.trim() === ''){
            ErrorMessage('Debe indicar la Contraseña');
            //refPassword.current.focus();
            return {
                success: false,
                field: 'password'
            }
        }
    }
    //Domicilio
    if(!user.street || user.street.trim() === ''){
        ErrorMessage('Debe indicar la Calle');
        //refAddress.current.focus();
        return {
            success: false,
            field: 'street'
        }
    }
    if(!user.number || user.number.trim() === ''){
        ErrorMessage('Debe indicar el Número');
        //refAddress.current.focus();
        return {
            success: false,
            field: 'number'
        }
    }
    //Ciudad
    if(!user.city || user.city.trim() === ''){
        ErrorMessage('Debe indicar la Ciudad');
        //refCity.current.focus();
        return {
            success: false,
            field: 'city'
        }
    }
    //State
    if(!user.state || user.state.trim() === ''){
        ErrorMessage('Debe indicar la Provincia');
        //refState.current.focus();
        return {
            success: false,
            field: 'state'
        }
    }
    //Zipcode
    if(!user.zipcode || user.zipcode.trim() === ''){
        ErrorMessage('Debe indicar el Código Postal');
        //refZipcode.current.focus();
        return {
            success: false,
            field: 'zipcode'
        }
    }
    //Default response
    return {
        success: true
    };
}