import {
    LIST_PRODUCTS,
    LIST_PRODUCTS_SUCCESS,
    LIST_PRODUCTS_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import authToken from '../config/token';
import { MSG_ERROR_SERVER_CONN } from '../config/messages';

//LISTAR CATEGORIAS
export function listProductsAction(params) {
    return async (dispatch) => {
        //Inicializo
        dispatch( listProducts() );
        //Ejecuto la consulta
        try{
            //Cargo la espera
            
            let queryParams;
            //Verifico si hay parámetros
            if(params){
                //Agrego el inicio
                if(!queryParams){
                    queryParams = '?';
                }
                //Agrego los parámetros
                Object.keys(params).map((key, index) => {
                    queryParams = `${queryParams}${key}=${params[key]}&`;
                });
            }
            //Busco el token de cliente
            const token = process.env.REACT_APP_CLIENT_TOKEN;
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.get(`/products${queryParams}`);
            //Obtengo los datos
            const res = response.data;
            //Llamo al dispatch
            dispatch( listProductsSuccess(res.data) );
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( listProductsError(data) );
        }
    }
}

const listProducts = () => ({
    type: LIST_PRODUCTS,
    payload: true
});

const listProductsSuccess = categorys => ({
    type: LIST_PRODUCTS_SUCCESS,
    payload: categorys
});

const listProductsError = error => ({
    type: LIST_PRODUCTS_ERROR,
    payload: error
});