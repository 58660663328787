import './App.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import withClearCache from './ClearCache';

import store from './store';

import Main from './pages/Main';

const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function MainApp(props) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Main /> 
      </BrowserRouter>
    </Provider>
  );
}

export default App;
