import React from 'react';
import Swal from 'sweetalert2';
import { notify } from 'react-notify-toast';

export const ErrorMessage = title => {
    Swal.mixin({
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        timer: 3000,
        background: '#ffcdd2'
    }).fire({
        icon: 'error',
        title
    })
}

export const SuccessMessage = title => {
    Swal.mixin({
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        timer: 5000,
        background: '#f1f8e9',
    }).fire({
        icon: 'success',
        title
    })
}

export const InfoMessage = title => {
    let config = { background: 'rgba(255, 204, 41, 1)', text: "#ffffff" }

    notify.show(
        <h4 style={{ fontFamily: 'Roboto', color: 'rgba(92, 158, 119, 1)' }} className="mb-0">
            <i className="fa fa-info-circle mr-2"></i>
            <strong>{title}</strong>
        </h4>, 
        "custom", 
        3000, 
        config);
}

export const WaitingMessage = title => {
    Swal.fire({
        title,
        text: 'Por favor aguarde.',
        onBeforeOpen: () => {
            Swal.showLoading()
        },
        allowOutsideClick: false
    });
}

export const ActionMessage = (title, text, type, handle) => {
    Swal.fire({
        title,
        text,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
        icon: type
    }).then((result) => {
        //Ejecuto función
        handle();
    })
}