import React from 'react';
import Slider from 'react-slick';

import Breadcrumb from '../layout/Breadcrumb';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

const About = () => {
    //Settings de Slider
    const settings = {
        autoplay: false,
        autoplaySpeed: 5000
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />

            <Breadcrumb title="Nosotros" />

            <section className="about-page section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7" style={{ textAlign: 'justify' }}>
                            <h4>El arte de comer saludable</h4>
                            <p>Filosofía de CocinArte: Sin ser vegana, es la alimentación que más me gusta, es un estilo de vida, a través de la cual uno puede sanar su cuerpo físico para lograr una mayor conexión con el Espiritu en pos de vivir y de sentir un sano equilibrio entre los aspectos fundamentales que nos conforman en todos los niveles de nuestras energías.</p>
                            <p>Mi misión es inspirar a todos uds a nutrir su cuerpo con alimentos que les de vitalidad, porque les aseguro que el disfrute de cada día se siente diferente y asi puedan hacer lo que aman mas conscientes.</p>
                            <p>Mientras aquí estoy para guiarlos y acompañarlos en un camino hacia un estilo de vida saludable, de a poco les sugiero ir reemplazando ciertos productos por otros más naturales, pequeños cambios que se convierten en grandes beneficios para nuestro organismo.</p>
                        </div>
                        <div className="col-xl-5 about-image">
                            <img src="/assets/img/about/about.jpg" className="img-fluid"  alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-12">
                            <Slider { ...settings } className="slide-1 testimonial-slider no-arrow">
                                <div>
                                    <div className="upper-part">
                                        <div className="text-center">
                                            <img src="/assets/img/about/cata.jpg" alt="#" />
                                        </div>
                                        <div className="testimonial-body text-center">
                                            <p>
                                                Hola soy cata, una apasionada de la cocina en todas sus formas. Licenciada en administración de empresas por profesión, pero no deje nunca de formarme e investigar el mundo de la cocina y aquí estoy, hoy siendo chef vegana-naturista. Motivadora en salud y emprendedora.
                                            </p>
                                            <h5>Catalina Sanchez Ducca</h5>
                                            <h6>Fundadora</h6>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </React.Fragment>
    );
};

export default About;