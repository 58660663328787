import React from 'react';

const ItemAddedRelated = () => {
    return (
        <div className="product-section">
            <div className="col-12 product-upsell text-center">
                <h4>Los clientes que compraron esto también compraron lo siguiente.</h4>
            </div>
            <div className="row" id="upsell_product">
                <div className="product-box col-sm-3 col-6">
                    <div className="img-wrapper">
                        <div className="front">
                            <a href="product-page.html">
                                <img src="http://themes.pixelstrap.com/bigboost/assets/images/product/6.jpg" className="img-fluid  mb-1" alt="cotton top" />
                            </a>
                        </div>
                        <div className="product-detail">
                            <h6><a href="!#"><span>cotton top</span></a></h6>
                            <h4><span>$25</span></h4>
                        </div>
                    </div>
                </div>
                <div className="product-box col-sm-3 col-6">
                    <div className="img-wrapper">
                        <div className="front">
                            <a href="product-page.html">
                                <img src="http://themes.pixelstrap.com/bigboost/assets/images/product/6.jpg" className="img-fluid  mb-1" alt="cotton top" />
                            </a>
                        </div>
                        <div className="product-detail">
                            <h6><a href="!#"><span>cotton top</span></a></h6>
                            <h4><span>$25</span></h4>
                        </div>
                    </div>
                </div>
                <div className="product-box col-sm-3 col-6">
                    <div className="img-wrapper">
                        <div className="front">
                            <a href="product-page.html">
                                <img src="http://themes.pixelstrap.com/bigboost/assets/images/product/6.jpg" className="img-fluid  mb-1" alt="cotton top" />
                            </a>
                        </div>
                        <div className="product-detail">
                            <h6><a href="!#"><span>cotton top</span></a></h6>
                            <h4><span>$25</span></h4>
                        </div>
                    </div>
                </div>
                <div className="product-box col-sm-3 col-6">
                    <div className="img-wrapper">
                        <div className="front">
                            <a href="product-page.html#">
                                <img src="http://themes.pixelstrap.com/bigboost/assets/images/product/6.jpg" className="img-fluid  mb-1" alt="cotton top" />
                            </a>
                        </div>
                        <div className="product-detail">
                            <h6><a href="!#"><span>cotton top</span></a></h6>
                            <h4><span>$25</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemAddedRelated;