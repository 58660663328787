import React from 'react';
import Swal from 'sweetalert2';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import { useState } from 'react';
import { WaitingMessage, ErrorMessage, SuccessMessage } from '../helpers/ToastMessages';
//Axios
import clienteAxios from '../../config/axios';
import { MSG_ERROR_SERVER, MSG_RESETING_PASSWORD, MSG_RESET_PASSWORD_SUCCESS } from '../../config/messages';
import { useHistory, useParams } from 'react-router';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loader from '../extras/Loader';
//Función principal
const ResetPassword = () => {
    //Tomo el history
    const history = useHistory();
    //Tomo los parámetros
    const { token } = useParams();
    //Tomo el store
    const authUser = useSelector( state => state.user.user );
    //Si está seteado el usuario, redirecciono
    if(authUser){
        history.push('/');
    }
    //Inicializo el state local
    const [ user, setUser ] = useState({
        email: '',
        password: '',
        password_confirmation: ''
    });
    //Manejo los cambios
    const handleChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    //Manejo el form
    const handleSubmit = async e => {
        e.preventDefault();
        
        //Valido
        if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(user.email) ||/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/.test(user.email)) {
            //Valido las claves
            if(user.password.trim().length < 6){
                ErrorMessage('La contraseña debe tener al menos 6 caracteres');
                return false;
            }
            //Confirmo claves
            if(user.password !== user.password_confirmation){
                ErrorMessage('Las contraseñas deben coincidir');
                return false;
            }
            //Envío el mail
            try{
                //Cargo la espera
                WaitingMessage(MSG_RESETING_PASSWORD);
                //Armo los datos
                const data = {
                    ...user,
                    token
                }
                //Consulta
                await clienteAxios.post(`/reset-password-token`, data);
                //Oculto espesra
                Swal.close();
                //Mensaje
                SuccessMessage(MSG_RESET_PASSWORD_SUCCESS);
                //Redirecciono
                history.push('/iniciar-sesion')
            }catch(error){
                const response = error.response;
                let data = {
                    data: null,
                    message: MSG_ERROR_SERVER
                };
                //Verifico si hay error
                if(response){
                    data = response.data;
                }
                //Oculto espesra
                Swal.close();
                //Muestro mensaje
                ErrorMessage(data.message);
            }
        }else{
            ErrorMessage('Debe indicar un E-mail válido');
        }
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                authUser ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <Header />
                        <Breadcrumb title="Recuperar contraseña" />
                        <section className="pwd-page section-b-space ">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 ">
                                        <h2 style={{textTransform: 'none'}}>Resetear contraseña</h2>
                                        <form className="theme-form" onSubmit={handleSubmit} autoComplete="off">
                                            <div className="form-row">
                                                <div className="col-12">
                                                    <Alert variant="warning">
                                                        Ingrese su dirección de mail, luego su nueva contraseña y confirme la misma.
                                                    </Alert>
                                                </div>
                                                <div className="col-md-12">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Ingrese su Email" 
                                                        required
                                                        name="email"
                                                        value={user.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <input 
                                                        type="password" 
                                                        className="form-control" 
                                                        placeholder="Ingrese su nueva contraseña" 
                                                        required
                                                        name="password"
                                                        value={user.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <input 
                                                        type="password" 
                                                        className="form-control" 
                                                        placeholder="Confirme su nueva contraseña" 
                                                        required
                                                        name="password_confirmation"
                                                        value={user.password_confirmation}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <button className="btn btn-solid" type="submit">
                                                    Resetear contraseña
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default ResetPassword;