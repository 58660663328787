import React from 'react';
import { Link } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCartAction } from '../../actions/userActions';
import FreeShippingAlert from '../extras/FreeShippingAlert';
const CartPanel = props => {
    //Triago las props
    const { showCart, handleCart } = props;
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de carrito
    const cart = useSelector( state => state.user.cart );
    const cartLoading = useSelector( state => state.user.cartLoading );
    //Manejo el quitar
    const handleRemove = (e, item) => {
        e.preventDefault();
        //Si no hay espera
        if(cartLoading){
            //Si hay espera, no hago nada
            return false;
        }
        //Genero los datos a enviar
        const data = {
            product_id: item.product.id,
            qty: item.qty
        }
        //Quito la cantidad
        dispatch( removeFromCartAction(data) );
    }
    //Renderizo
    return (
        <div id="cart_side" className={`add_to_cart right ${showCart ? 'open-side' : null}`}>
            <a href="!#" className="overlay" onClick={handleCart}></a>
            <div className="cart-inner">
                <div className="cart_top">
                    <h3>Mi carrito</h3>
                    <div className="close-cart">
                        <a href="!#" onClick={handleCart}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
                <div className="cart_media">
                    {
                        cart && cart.items ? (
                            <ul className="cart_product">
                                {
                                    cart.items.map(item => (
                                        <li key={`cpi${item.id}`}>
                                            <div className="media">
                                                <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                    {
                                                        item.product && item.product.images.length > 0 ? (
                                                            <img src={ item.product.images[0].img1 } className="mr-3" alt="" />
                                                        ) : (
                                                            <img alt="" className="mr-3" src="/assets/img/products/default_970.jpg" />
                                                        )
                                                    }
                                                </Link>
                                                <div className="media-body">
                                                    <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                        <h5>{ item.name }</h5>
                                                    </Link>
                                                    <h4>
                                                        <span>{`${item.qty} x $ ${item.price}`}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="close-circle">
                                                <a href="!#" onClick={e => handleRemove(e, item)}>
                                                    <i className="ti-trash" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>        
                        ) : null
                    }
                    <ul className="cart_total">
                        <li>
                            <div className='total'>
                                <h5>
                                    Descuentos : <span>$ {cart ? cart.all_discount : '-'}</span>
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div className="total">
                                <h5>Subtotal : <span>$ <b>{cart ? cart.subtotal_with_discount : '-'}</b></span></h5>
                            </div>
                        </li>
                        <li>
                        <FreeShippingAlert cart={cart} />
                        </li>
                        <li>
                            <div className="buttons">
                                <Link to="/carrito" className="btn btn-solid btn-block btn-solid-sm view-cart">
                                    Ver carrito
                                </Link>
                                <Link to="/carrito/checkout" className="btn btn-solid btn-solid-sm btn-block checkout">
                                    Finalizar compra
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CartPanel;