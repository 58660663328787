import React from 'react';
import { useLocation, useHistory } from 'react-router';
import Loader from './extras/Loader';
//Redux
import { useDispatch } from 'react-redux';
import { getOderPaymentInfoAction } from '../actions/orderActions';
//Función principal
const Loading = () => {
    //Inicializo el history
    const history = useHistory();
    //Tomo el location
    const location = useLocation();
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo los parámetros
    const searchParams = new URLSearchParams(location.search);
    //Evalúo si es transacción
    if(searchParams.has('q') && searchParams.has('r') && searchParams.has('on')){
        //Tomo los parámetros
        const r = searchParams.get('r');
        const order = searchParams.get('on');
        //Armo los datos
        const data = {
            result: r,
            on: order,
            collection_id: searchParams.get('collection_id'),
            collection_status: searchParams.get('collection_status'),
            external_reference: searchParams.get('external_reference'),
            payment_type: searchParams.get('payment_type'),
            merchant_order_id: searchParams.get('merchant_order_id'),
            preference_id: searchParams.get('preference_id')
        }
        //Llamo al dispatch
        dispatch( getOderPaymentInfoAction(data, history) );
    }
    //Si no hay datos, redirecciono
    history.push('/inicio');
    //Renderizo
    return (
        <Loader />
    );
};

export default Loading;