import React from 'react';

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <img src="/assets/img/loader-logo.gif" className="img-fluid" alt='' />
        </div>
    );
};

export default Loader;