import {
    GET_ORDER_PAYMENT,
    GET_ORDER_PAYMENT_SUCCESS,
    GET_ORDER_PAYMENT_ERROR,
} from '../types';

import clienteAxios from '../config/axios';

import { ErrorMessage, WaitingMessage } from '../components/helpers/ToastMessages';
import Swal from 'sweetalert2';

import Cookie from '../services/CookieService';
import { RESULT_SUCCESS } from '../data/mercadopago';
import { COOKIE_ACCESS_TOKEN, COOKIE_CART, COOKIE_CART_GUEST } from '../config/cookies';
import { MSG_ERROR_SERVER_CONN } from '../config/messages';

//GET ORDER PAYMENT INFO
export function getOderPaymentInfoAction(data, history) {
    return async (dispatch) => {
        //Inicializo
        dispatch( getOderPaymentInfo() );
        //Ejecuto la consulta
        try{
            //Cargo la espera
            WaitingMessage('Procesando');
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                cartCookie = COOKIE_CART_GUEST;
            }
            //Lo agrego para la consulta
            // authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`/order/checkout/payment`, data);
            //Obtengo los datos
            const res = response.data;
            //Llamo al dispatch
            dispatch( getOderPaymentInfoSuccess(res.data) );
            //Elimino la cookie del Carro
            Cookie.remove(cartCookie);
            //Oculto espera
            Swal.close();
            //Redirecciono según el resultado
            if(data.result === RESULT_SUCCESS){
                history.push(`/carrito/checkout/success?oid=${res.data.number}`);
            }else{
                history.push('/carrito/checkout');
            }
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( getOderPaymentInfoError(data) );
            //Oculto espera
            Swal.close();
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const getOderPaymentInfo = () => ({
    type: GET_ORDER_PAYMENT,
    payload: true
});

const getOderPaymentInfoSuccess = order => ({
    type: GET_ORDER_PAYMENT_SUCCESS,
    payload: order
});

const getOderPaymentInfoError = error => ({
    type: GET_ORDER_PAYMENT_ERROR,
    payload: error
});