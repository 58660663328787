import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, logoutAction } from '../../actions/userActions';

//Función principal
const LoginPanel = props => {
    //Genero los ref
    const refEmail = useRef();
    const refPassword = useRef();
    //Tomo los props
    const { showLogin, handleLogin } = props;
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store
    const authLoading = useSelector( state => state.user.loading );
    const authError = useSelector( state => state.user.error );
    const authUser = useSelector( state => state.user.user );
    //Inicializo el state local
    const [ user, setUser ] = useState({
        email: '',
        password: ''
    })
    //Manejo los cambios
    const handleChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    //Manejo el submit
    const handleSubmit = e => {
        e.preventDefault();
        //Debo verificar los datos
        if(!user.email || user.email.trim() === ''){
            refEmail.current.focus();
            return false;
        }
        if(!user.password || user.password.trim().length < 4){
            refPassword.current.focus();
            return false;
        }
        //Ejecuto el login
        dispatch( loginAction(user) );
        //Una vez finalizado, reinicio
        setUser({
            email: '',
            password: ''
        })
    }
    //Manejo el cierre de sesión
    const handleLogout = () => {
        dispatch( logoutAction() );
    }
    //Renderizo
    return (
        <div id="myAccount" className={`add_to_cart right ${showLogin ? 'open-side' : null}`}>
            <a href="!#" className="overlay" onClick={handleLogin}></a>
            <div className="cart-inner">
                <div className="cart_top">
                    <h3>Mi cuenta</h3>
                    <div className="close-cart">
                        <a href="!#" onClick={handleLogin}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
                {
                    authUser ? (
                        <div className="cart_media">
                            <ul className="cart_total">
                                <li>
                                    <div className="total">
                                        <h5>{ authUser.account.first_name+' '+authUser.account.last_name } </h5>
                                    </div>
                                </li>
                                <li>
                                    <div className="total">
                                        <h5 style={{textTransform: 'lowercase'}}>{ authUser.account.email } </h5>
                                    </div>
                                </li>
                                <li>
                                    <div className="total">
                                        <h5>
                                            {
                                                `${authUser.account.street} ${authUser.account.number}`
                                            }
                                            {
                                                authUser.account.floor ? (` - Piso: ${authUser.account.floor}`) : null
                                            }
                                            {
                                                authUser.account.apartment ? (` - Depto.: ${authUser.account.apartment}`) : null
                                            }
                                        </h5>
                                    </div>
                                </li>
                                <li>
                                    <div className="total">
                                        <h5>{ authUser.account.city } </h5>
                                    </div>
                                </li>
                                <li>
                                    <div className="total">
                                        <h5>{ authUser.account.state } </h5>
                                    </div>
                                </li>
                                <li>
                                    <div className="buttons">
                                        <Link to="/carrito" className="btn btn-solid btn-block btn-solid-sm btn-green">
                                            Ver carrito
                                        </Link>
                                        <Link to="/mis-pedidos" className="btn btn-solid btn-block btn-solid-sm">
                                            Mis Pedidos
                                        </Link>
                                        <button className="btn btn-solid btn-solid-sm btn-block btn-red" onClick={handleLogout}>
                                            Cerrar sesión
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <form className="theme-form" onSubmit={handleSubmit} autoComplete="off">
                            {
                                authError ? (
                                    <Alert variant="danger">
                                        { authError.message }
                                    </Alert>
                                ) : null
                            }
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="email" 
                                    value={user.email}
                                    placeholder="Email" 
                                    onChange={handleChange}
                                    required 
                                    ref={refEmail} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="review">Contraseña</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name="password" 
                                    value={user.password}
                                    placeholder="Ingrese su contraseña" 
                                    onChange={handleChange}
                                    required
                                    ref={refPassword} />
                            </div>
                            <button 
                                className="btn btn-solid btn-solid-sm btn-block" 
                                type="submit"
                                disabled={authLoading}
                            >
                                Ingresar
                            </button>
                            <h5 className="forget-class">
                                <Link to='/recuperar-contrasena' className="d-block">
                                    Olvidó su contraseña?
                                </Link>
                            </h5>
                            <h5 className="forget-class">
                                <Link to="/registracion" className="d-block">
                                    Nuevo en la tienda? Regístrese
                                </Link>
                            </h5>
                        </form>
                    )
                }
            </div>
        </div>
    );
};

export default LoginPanel;