import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    GET_USER_DATA,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_ERROR,
    ADD_USER,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    SHOW_REGISTRATION,
    SHOW_REGISTRATION_SUCCESS,
    LIST_WISHLIST,
    LIST_WISHLIST_SUCCESS,
    LIST_WISHLIST_ERROR,
    ADD_WISHLIST,
    ADD_WISHLIST_SUCCESS,
    ADD_WISHLIST_ERROR,
    GET_CART,
    GET_CART_SUCCESS,
    GET_CART_ERROR,
    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_ERROR,
    REMOVE_FROM_CART,
    REMOVE_FROM_CART_SUCCESS,
    REMOVE_FROM_CART_ERROR,
    REMOVE_WISHLIST,
    REMOVE_WISHLIST_SUCCESS,
    REMOVE_WISHLIST_ERROR,
    ADD_WISHLIST_TO_CART,
    ADD_WISHLIST_TO_CART_SUCCESS,
    ADD_WISHLIST_TO_CART_ERROR,
    APPLY_COUPON,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_ERROR,
    APPLY_SHIPMENT,
    APPLY_SHIPMENT_SUCCESS,
    APPLY_SHIPMENT_ERROR,
    ADD_ORDER,
    ADD_ORDER_SUCCESS,
    ADD_ORDER_ERROR,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_ERROR,
    UPDATE_CART,
    UPDATE_CART_SUCCESS,
    UPDATE_CART_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import authToken from '../config/token';

import { ActionMessage, ErrorMessage, InfoMessage, SuccessMessage, WaitingMessage } from '../components/helpers/ToastMessages';
import Swal from 'sweetalert2';

import Cookie from '../services/CookieService';
import { ONLINE_PAYMENT_NAME } from '../data/paymentMethods';
import { COOKIE_ACCESS_TOKEN, COOKIE_CART, COOKIE_CART_GUEST, COOKIE_USER_DATA } from '../config/cookies';
import { MSG_CART_ADDING_ITEM, MSG_CART_ADD_ITEM_SUCCESS, MSG_CART_COUPON_ADD_SUCCESS, MSG_CART_UPDATE_SUCCESS, MSG_CART_UPDATING, MSG_EDIT_USER_SUCCESS, MSG_ERROR_SERVER, MSG_ERROR_SERVER_CONN, MSG_ERROR_WISHLIST_NOT_USER, MSG_LOGIN_IN, MSG_LOGIN_OUT, MSG_PLEASE_WAIT, MSG_REGISTERING_USER, MSG_UPDATING_USER, MSG_WISHLIST_ADD_SUCCESS, MSG_WISHLIST_REMOVING, MSG_WISHLIST_TO_CART } from '../config/messages';
import { CHECK_CART } from '../data/responseCodes';

//login
export function loginAction(user, history) {
    return async (dispatch) => {
        //Cargo la espera
        Swal.fire({
            title: MSG_LOGIN_IN,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicio la ejecución
        dispatch( login() );
        //process.env.REACT_APP_TOKEN_NAME
        try{
            //Inicializo la respuesta
            const response = await clienteAxios.post('/login', user);
            //Obtengo los datos
            const res = response.data;
            //Tomo el token
            const token = res.data.token;
            //Guardo los datos en la cookie
            Cookie.set(COOKIE_ACCESS_TOKEN, token);
            Cookie.set(COOKIE_USER_DATA, {user: res.data.user, account: res.data.account});
            //Debo recargar los datos del carro
            authToken(token);
            //Consulto el carro del usuario
            const cartResponse = await clienteAxios.get(`user/cart`);
            //Obtengo los datos del carro
            const cartRes = cartResponse.data;
            //Guardo los datos en la cookie
            Cookie.set(COOKIE_CART, JSON.stringify(cartRes.data));
            //Armo los datos
            const data = {
                user: res.data,
                cart: cartRes.data
            }
            //Llamo al success
            dispatch( loginSuccess(data) );
            //Oculto la espera
            Swal.close();
            //Redirección
            if(history)
                history.push('/');
        }catch(error){
            const response = error.response;
            let data = {
                error: MSG_ERROR_SERVER,
                message: MSG_ERROR_SERVER_CONN
            };
            //Me fijo si hay respuesta
            if(response){
                data = response.data;
            }
            //Oculto la espera
            Swal.close();
            //Llamo al dispatch
            dispatch( loginError(data) );
        }
    }
}

const login = () => ({
    type: LOGIN,

})

const loginSuccess = data => ({
    type: LOGIN_SUCCESS,
    payload: data
})

const loginError = error => ({
    type: LOGIN_ERROR,
    payload: error
})

//Logout
export function logoutAction() {
    return async (dispatch) => {
        //Cargo la espera
        Swal.fire({
            title: MSG_LOGIN_OUT,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicio la ejecución
        dispatch( logout() );
        //Inicializo la data
        let data = {
            user: null,
            cart: {},
            wishlist: null
        }
        //Elimino las cookies del usuario
        Cookie.remove(COOKIE_ACCESS_TOKEN);
        Cookie.remove(COOKIE_USER_DATA);
        Cookie.remove(COOKIE_CART);
        //Busco si hay una cookie de guest
        const cart = Cookie.get(COOKIE_CART_GUEST);
        //Me fijo si existe
        if(cart && cart.id){
            //Agrego el item
            const urlSuffix = `?cart_id=${cart.id}`
            //Busco el token de cliente
            const token = process.env.REACT_APP_CLIENT_TOKEN;
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.get(`/cart${urlSuffix}`);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(COOKIE_CART_GUEST, JSON.stringify(res.data));
            //Seteo los datos
            data = {
                ...data,
                cart: res.data
            }
        }
        //Llamo al success
        dispatch( logoutSuccess(data) );
        //Oculto la espera
        Swal.close();
    }
}

const logout = () => ({
    type: LOGOUT,

})

const logoutSuccess = data => ({
    type: LOGOUT_SUCCESS,
    payload: data
})

//GET USER DATA
export function getUserDataAction(user) {
    return async (dispatch) => {
        //Inicio la ejecución
        dispatch( getUserData() );
        //Verifico si hay que buscar los datos
        if(!user){
            try{
                //Busco el token de usuario
                //const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
                const token = Cookie.get(COOKIE_ACCESS_TOKEN);
                //Si hay token, debo traer los datos
                if(token){
                    //Lo agrego para la consulta
                    authToken(token);
                    //Inicializo la respuesta
                    const response = await clienteAxios.get('/user');
                    //Obtengo los datos
                    const res = response.data;
                    //Lo agrego para la consulta
                    authToken(token);
                    //Consulto el carro del usuario
                    const cartResponse = await clienteAxios.get(`user/cart`);
                    //Obtengo los datos del carro
                    const cartRes = cartResponse.data;
                    //Guardo los datos en la cookie
                    Cookie.set('cart', JSON.stringify(cartRes.data));
                    //Lo agrego para la consulta
                    authToken(token);
                    //Consulta por la wishlist
                    const wishListResponse = await clienteAxios.get(`user/wishlist`);
                    //Obtengo los datos
                    const wishlistRes = wishListResponse.data;
                    //Armo los datos
                    const data = {
                        user: res.data,
                        cart: cartRes.data,
                        wishlist: wishlistRes.data
                    }
                    //Llamo al success
                    dispatch( getUserDataSuccess(data) );
                }else{
                    //Armo los datos
                    const data = {
                        user: null,
                        cart: {},
                        wishlist: null
                    }
                    //Llamo al success
                    dispatch( getUserDataSuccess(data) );
                }
            }catch(error){
                const response = error.response;
                let data = {
                    error: MSG_ERROR_SERVER,
                    message: MSG_ERROR_SERVER_CONN
                };
                //Me fijo si hay respuesta
                if(response){
                    data = response.data;
                }
                //Llamo al dispatch
                dispatch( getUserDataError(data) );
            }
        }else{
            //Busco el token de cliente
            const token = Cookie.get(COOKIE_ACCESS_TOKEN);
            //Me fijo si hay token
            if(token){
                //Inicializo datos
                let data = {
                    user,
                    cart: {},
                    wishlist: null
                }
                //Lo agrego para la consulta
                authToken(token);
                //Consulto el carro del usuario
                const cartResponse = await clienteAxios.get(`user/cart`);
                //Obtengo los datos del carro
                const cartRes = cartResponse.data;
                //Guardo los datos en la cookie
                Cookie.set('cart', JSON.stringify(cartRes.data));
                //Lo agrego para la consulta
                authToken(token);
                //Consulta por la wishlist
                const wishListResponse = await clienteAxios.get(`user/wishlist`);
                //Obtengo los datos
                const wishlistRes = wishListResponse.data;
                //Armo los datos
                data = {
                    ...data,
                    cart: cartRes.data,
                    wishlist: wishlistRes.data
                }
                //Llamo al success
                dispatch( getUserDataSuccess(data) );
            }
        }
    }
}

const getUserData = () => ({
    type: GET_USER_DATA,

})

const getUserDataSuccess = data => ({
    type: GET_USER_DATA_SUCCESS,
    payload: data
})

const getUserDataError = error => ({
    type: GET_USER_DATA_ERROR,
    payload: error
})

//ADD USER
export function addUserAction(user, history) {
    return async (dispatch) => {
        //Cargo la espera
        Swal.fire({
            title: MSG_REGISTERING_USER,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicio la ejecución
        dispatch( addUser() );
        //process.env.REACT_APP_TOKEN_NAME
        try{
            //Busco el token de cliente
            const token = process.env.REACT_APP_CLIENT_TOKEN;
            //Lo agrego para la consulta
            authToken(token);
            //Inicializo la respuesta
            const response = await clienteAxios.post('/register', user);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(COOKIE_ACCESS_TOKEN, res.data.token);
            Cookie.set(COOKIE_USER_DATA, {user: res.data.user, account: res.data.account});
            //Llamo al success
            dispatch( addUserSuccess(res.data) );
            //Oculto la espera
            Swal.close();
            //Redirección
            history.push('/');
        }catch(error){
            const response = error.response;
            //Oculto la espera
            Swal.close();
            dispatch( addUserError((response) ? response.data : null) );
        }
    }
}

const addUser = () => ({
    type: ADD_USER,

})

const addUserSuccess = user => ({
    type: ADD_USER_SUCCESS,
    payload: user
})

const addUserError = error => ({
    type: ADD_USER_ERROR,
    payload: error
})

//UPDATE PROFILE
export function editUserAction(user, history) {
    return async (dispatch) => {
        //Cargo la espera
        Swal.fire({
            title: MSG_UPDATING_USER,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicio la ejecución
        dispatch( editUser() );
        //process.env.REACT_APP_TOKEN_NAME
        try{
            //Busco el token de cliente
            const token = Cookie.get(COOKIE_ACCESS_TOKEN);
            //Lo agrego para la consulta
            authToken(token);
            //Inicializo la respuesta
            const response = await clienteAxios.post('/profile-update', user);
            //Obtengo los datos
            const res = response.data;
            //Llamo al success
            dispatch( editUserSuccess(res.data) );
            //Oculto la espera
            Swal.close();
            //Mensaje
            SuccessMessage(MSG_EDIT_USER_SUCCESS);
        }catch(error){
            const response = error.response;
            //Oculto la espera
            Swal.close();
            dispatch( editUserError((response) ? response.data : null) );
        }
    }
}

const editUser = user => ({
    type: EDIT_USER,
    payload: user
})

const editUserSuccess = user => ({
    type: EDIT_USER_SUCCESS,
    payload: user
})

const editUserError = error => ({
    type: EDIT_USER_ERROR,
    payload: error
})

//SHOW REGISTRATION
export function showRegistrationAction(history) {
    return async (dispatch) => {
        //Inicio la ejecución
        dispatch( showRegistration() );
        //Llamo al success
        dispatch( showRegistrationSuccess() );
        //Redirecciono
        history.push('/registracion');
    }
}

const showRegistration = () => ({
    type: SHOW_REGISTRATION,

})

const showRegistrationSuccess = () => ({
    type: SHOW_REGISTRATION_SUCCESS,
    payload: null
})

//CART FUNCTIONS

//GET CART
export function getCartAction() {
    return async (dispatch) => {
        //Inicializo
        dispatch( getCart() );
        //Ejecuto la consulta
        try{
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let urlSuffix = '';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                cartCookie = COOKIE_CART_GUEST;
                //Busco el token de cliente
                token = process.env.REACT_APP_CLIENT_TOKEN;
                urlPrefix = '';
                //Tengo que buscar el ID del carro
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    urlSuffix = `?cart_id=${cart.id}`
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.get(`${urlPrefix}/cart${urlSuffix}`);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( getCartSuccess(res.data) );
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( getCartError(data) );
        }
    }
}

const getCart = () => ({
    type: GET_CART,
    payload: true
});

const getCartSuccess = cart => ({
    type: GET_CART_SUCCESS,
    payload: cart
});

const getCartError = error => ({
    type: GET_CART_ERROR,
    payload: error
});

//ADD TO CART
export function addToCartAction(item, callback) {
    return async (dispatch) => {
        //Inicializo
        dispatch( addToCart() );
        //Ejecuto la consulta
        try{
            let data = {
                ...item
            }
            //Muestro espera
            WaitingMessage(MSG_CART_ADDING_ITEM);
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                //Busco el token de cliente
                urlPrefix = '';
                cartCookie = COOKIE_CART_GUEST;
                //Tengo que buscar el ID del carro de visitante
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    data = {
                        ...data,
                        cart_id: cart.id
                    }
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`${urlPrefix}/cart/add-item`, data);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( addToCartSuccess(res.data) );
            //Oculto espesra
            Swal.close();
            //Mensaje
            SuccessMessage(MSG_CART_ADD_ITEM_SUCCESS);
            //Check free shiping
            if(res.data.free_shipping_remain < 0){
                setTimeout(() => {
                    InfoMessage(`Te falta $${ Math.abs(res.data.free_shipping_remain).toFixed(2) } para tener envío gratis.`)
                }, 1000);
            }
            //Me fijo si hay callback
            if(callback){
                callback();
            }
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( addToCartError(data) );
            //Oculto espesra
            Swal.close();
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const addToCart = () => ({
    type: ADD_TO_CART,
    payload: true
});

const addToCartSuccess = cart => ({
    type: ADD_TO_CART_SUCCESS,
    payload: cart
});

const addToCartError = error => ({
    type: ADD_TO_CART_ERROR,
    payload: error
});

//REMOVE FROM CART
export function removeFromCartAction(item) {
    return async (dispatch) => {
        //Cargo la espera
        Swal.fire({
            title: MSG_CART_UPDATING,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicializo
        dispatch( removeFromCart() );
        //Ejecuto la consulta
        try{
            let data = {
                ...item
            }
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                urlPrefix = '';
                cartCookie = COOKIE_CART_GUEST;
                //Tengo que buscar el ID del carro
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    data = {
                        ...data,
                        cart_id: cart.id
                    }
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`${urlPrefix}/cart/remove-item`, data);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( removeFromCartSuccess(res.data) );
            //Oculto la espera
            Swal.close();
            //Mensaje
            SuccessMessage(MSG_CART_UPDATE_SUCCESS);
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( removeFromCartError(data) );
            //Oculto la espera
            Swal.close();
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const removeFromCart = () => ({
    type: REMOVE_FROM_CART,
    payload: true
});

const removeFromCartSuccess = cart => ({
    type: REMOVE_FROM_CART_SUCCESS,
    payload: cart
});

const removeFromCartError = error => ({
    type: REMOVE_FROM_CART_ERROR,
    payload: error
});

//LISTAR WISHLIST
export function listWishlistAction() {
    return async (dispatch) => {
        //Inicializo
        dispatch( listWishlist() );
        //Ejecuto la consulta
        try{
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let data = null;
            //Me fijo si existe
            if(token){
                //Lo agrego para la consulta
                authToken(token);
                //Hago la consulta
                const response = await clienteAxios.get(`${urlPrefix}/wishlist`);
                //Obtengo los datos
                const res = response.data;
                //Agrego la data
                data = res.data;
            }
            //Llamo al dispatch
            dispatch( listWishlistSuccess(data) );
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( listWishlistError(data) );
        }
    }
}

const listWishlist = () => ({
    type: LIST_WISHLIST,
    payload: true
});

const listWishlistSuccess = wishlist => ({
    type: LIST_WISHLIST_SUCCESS,
    payload: wishlist
});

const listWishlistError = error => ({
    type: LIST_WISHLIST_ERROR,
    payload: error
});

//ADD WISHLIST
export function addWishlistAction(item) {
    return async (dispatch) => {
        //Inicializo
        dispatch( addWishlist() );
        //Ejecuto la consulta
        try{
            //Armo los datos
            const pData = {
                'product_id': item.id
            }
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let data = null;
            //Me fijo si existe
            if(token){
                //Lo agrego para la consulta
                authToken(token);
                //Hago la consulta
                const response = await clienteAxios.post(`${urlPrefix}/wishlist/add-product`, pData);
                //Obtengo los datos
                const res = response.data;    
                //Seteo los datos
                data = res.data;
                //Mensaje
                SuccessMessage(MSG_WISHLIST_ADD_SUCCESS);
            }
            //Llamo al dispatch
            dispatch( addWishlistSuccess(data) );
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( addWishlistError(data) );
        }
    }
}

const addWishlist = () => ({
    type: ADD_WISHLIST,
    payload: true
});

const addWishlistSuccess = wishlist => ({
    type: ADD_WISHLIST_SUCCESS,
    payload: wishlist
});

const addWishlistError = error => ({
    type: ADD_WISHLIST_ERROR,
    payload: error
});

//REMOVE WISHLIST
export function removeWishlistAction(item) {
    return async (dispatch) => {
        //Muestro espera
        Swal.fire({
            title: MSG_WISHLIST_REMOVING,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicializo
        dispatch( removeWishlist() );
        //Ejecuto la consulta
        try{
            //Armo los datos
            const pData = {
                'product_id': item.id
            }
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let data = null
            //Me fijo si existe
            if(token){
                //Lo agrego para la consulta
                authToken(token);
                //Hago la consulta
                const response = await clienteAxios.post(`${urlPrefix}/wishlist/remove-product`, pData);
                //Obtengo los datos
                const res = response.data;
                //Seteo los datos
                data = res.data;    
            }
            //Llamo al dispatch
            dispatch( removeWishlistSuccess(data) );
            //Oculto la espera
            Swal.close();
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( removeWishlistError(data) );
            //Oculto la espera
            Swal.close();
        }
    }
}

const removeWishlist = () => ({
    type: REMOVE_WISHLIST,
    payload: true
});

const removeWishlistSuccess = wishlist => ({
    type: REMOVE_WISHLIST_SUCCESS,
    payload: wishlist
});

const removeWishlistError = error => ({
    type: REMOVE_WISHLIST_ERROR,
    payload: error
});

//MOVE TO CART
export function addWishlistToCartAction(item) {
    return async (dispatch) => {
        //Cargo la espear
        Swal.fire({
            title: MSG_WISHLIST_TO_CART,
            text: MSG_PLEASE_WAIT,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Inicializo
        dispatch( addWishlistToCart() );
        //Ejecuto la consulta
        try{
            //Armo los datos
            const pData = {
                'product_id': item.id
            }
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            //Me fijo si existe
            if(token){
                //Lo agrego para la consulta
                authToken(token);
                //Hago la consulta
                const response = await clienteAxios.post(`${urlPrefix}/wishlist/move-to-cart`, pData);
                //Obtengo los datos
                const res = response.data;    
                //Llamo al dispatch
                dispatch( addWishlistToCartSuccess(res.data) );
                //Oculto la espera
                Swal.close();
            }else{
                //Seteo error
                const data = {
                    data: null,
                    message: MSG_ERROR_WISHLIST_NOT_USER
                }
                //Llamo al dispatch
                dispatch( addWishlistToCartError(data) );    
                //Oculto la espera
                Swal.close();
            }
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( addWishlistToCartError(data) );
            //Oculto la espera
            Swal.close();
        }
    }
}

const addWishlistToCart = () => ({
    type: ADD_WISHLIST_TO_CART,
    payload: true
});

const addWishlistToCartSuccess = data => ({
    type: ADD_WISHLIST_TO_CART_SUCCESS,
    payload: data
});

const addWishlistToCartError = error => ({
    type: ADD_WISHLIST_TO_CART_ERROR,
    payload: error
});

//APPLY COUPON
export function applyCouponAction(code) {
    return async (dispatch) => {
        //Inicializo
        dispatch( applyCoupon() );
        //Ejecuto la consulta
        try{
            let data = {
                ...code
            }
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                urlPrefix = '';
                cartCookie = COOKIE_CART_GUEST;
                //Tengo que buscar el ID del carro
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    data = {
                        ...data,
                        cart_id: cart.id
                    }
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`${urlPrefix}/cart/apply-coupon`, data);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( applyCouponSuccess(res.data) );
            //Mensaje
            SuccessMessage(MSG_CART_COUPON_ADD_SUCCESS);
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( applyCouponError(data) );
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const applyCoupon = () => ({
    type: APPLY_COUPON,
    payload: true
});

const applyCouponSuccess = cart => ({
    type: APPLY_COUPON_SUCCESS,
    payload: cart
});

const applyCouponError = error => ({
    type: APPLY_COUPON_ERROR,
    payload: error
});

//APPLY SHIPMENT
export function applyShipmentAction(shipment) {
    return async (dispatch) => {
        //Inicializo
        dispatch( applyShipment() );
        //Ejecuto la consulta
        try{
            let data = {
                ...shipment
            }
            //Muestro la espera
            WaitingMessage(MSG_CART_UPDATING);
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                urlPrefix = '';
                cartCookie = COOKIE_CART_GUEST;
                //Tengo que buscar el ID del carro
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    data = {
                        ...data,
                        cart_id: cart.id
                    }
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`${urlPrefix}/cart/apply-shipment`, data);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( applyShipmentSuccess(res.data) );
            //Oculto la espesra
            Swal.close();
            //Mensaje
            //SuccessMessage('Cupón aplicado correctamente');
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( applyShipmentError(data) );
            //Oculto la espesra
            Swal.close();
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const applyShipment = () => ({
    type: APPLY_SHIPMENT,
    payload: true
});

const applyShipmentSuccess = cart => ({
    type: APPLY_SHIPMENT_SUCCESS,
    payload: cart
});

const applyShipmentError = error => ({
    type: APPLY_SHIPMENT_ERROR,
    payload: error
});


//ADD ORDER
export function addOrderAction(data, history) {
    return async (dispatch) => {
        //Inicializo
        dispatch( addOrder() );
        //Ejecuto la consulta
        try{
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            //Me fijo si existe
            if(!token){
                urlPrefix = '';
            }
            //Lo agrego para la consulta
            authToken(token);
            //Me fijo que tipo de pago es
            if(data.payment_method === ONLINE_PAYMENT_NAME){
                //Debo obtener el link de pago
                const response = await clienteAxios.post(`${urlPrefix}/order/online-payment`, data);
                //Obtengo los datos
                const res = response.data;
                //Llamo al dispatch
                dispatch( addOrderSuccess(res.data) );    
                //Redirecciono
                window.location = res.data.redirect;
            }else{
                //Debo generar la orden
                const response = await clienteAxios.post(`${urlPrefix}/order/add`, data);
                //Obtengo los datos
                const res = response.data;
                //Llamo al dispatch
                dispatch( addOrderSuccess(res.data) );
                //Oculto la espera
                Swal.close();
                //Redirecciono
                history.push(res.data.redirect);
            }
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Oculto la espera
            Swal.close();
            //Error
            dispatch( addOrderError(data) );
            //Me fijo si hay data, y veo el código
            if(data.data && data.data === CHECK_CART){
                //Mensaje con acción
                ActionMessage(data.message, 'Deberá revisar su carrito antes de finalizar la compra', 'warning', () => history.push('/carrito'));
            }else{
                //Mensaje
                ErrorMessage(data.message);
            }
        }
    }
}

const addOrder = () => ({
    type: ADD_ORDER,
    payload: true
});

const addOrderSuccess = cart => ({
    type: ADD_ORDER_SUCCESS,
    payload: cart
});

const addOrderError = error => ({
    type: ADD_ORDER_ERROR,
    payload: error
});

export function updatePaymentMethodAction(paymentMethod) {
    return async (dispatch) => {
        //Inicializo
        dispatch( updatePaymentMethod() );
        //Ejecuto la consulta
        try{
            let data = {
                payment_method: paymentMethod
            }
            //Muestro la espera
            WaitingMessage(MSG_CART_UPDATING);
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                urlPrefix = '';
                cartCookie = COOKIE_CART_GUEST;
                //Tengo que buscar el ID del carro
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    data = {
                        ...data,
                        cart_id: cart.id
                    }
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`${urlPrefix}/cart/apply-payment-method`, data);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( updatePaymentMethodSuccess(res.data) );
            //Oculto la espesra
            Swal.close();
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( updatePaymentMethodError(data) );
            //Oculto la espesra
            Swal.close();
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const updatePaymentMethod = () => ({
    type: UPDATE_PAYMENT_METHOD,
    payload: true
});

const updatePaymentMethodSuccess = cart => ({
    type: UPDATE_PAYMENT_METHOD_SUCCESS,
    payload: cart
});

const updatePaymentMethodError = error => ({
    type: UPDATE_PAYMENT_METHOD_ERROR,
    payload: error
});


export function updateCartAction(data) {
    return async (dispatch) => {
        //Inicializo
        dispatch( updateCart() );
        //Ejecuto la consulta
        try{
            //Muestro la espera
            WaitingMessage(MSG_CART_UPDATING);
            //Traigo token de usuario
            let token = Cookie.get(COOKIE_ACCESS_TOKEN);
            let urlPrefix = '/user';
            let cartCookie = COOKIE_CART;
            //Me fijo si existe
            if(!token){
                urlPrefix = '';
                cartCookie = COOKIE_CART_GUEST;
                //Tengo que buscar el ID del carro
                const cart = Cookie.get(cartCookie);
                //Me fijo si existe
                if(cart && cart.id){
                    //Agrego el item
                    data = {
                        ...data,
                        cart_id: cart.id
                    }
                }
            }
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.post(`${urlPrefix}/cart/update-cart`, data);
            //Obtengo los datos
            const res = response.data;
            //Guardo los datos en la cookie
            Cookie.set(cartCookie, JSON.stringify(res.data));
            //Llamo al dispatch
            dispatch( updateCartSuccess(res.data) );
            //Oculto la espesra
            Swal.close();
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Error
            dispatch( updateCartError(data) );
            //Oculto la espesra
            Swal.close();
            //Mensaje
            ErrorMessage(data.message);
        }
    }
}

const updateCart = () => ({
    type: UPDATE_CART,
    payload: true
});

const updateCartSuccess = cart => ({
    type: UPDATE_CART_SUCCESS,
    payload: cart
});

const updateCartError = error => ({
    type: UPDATE_CART_ERROR,
    payload: error
});