import {
    LIST_BRANDS,
    LIST_BRANDS_SUCCESS,
    LIST_BRANDS_ERROR
} from '../types';

//Cada reducer tiene su propio state
const initialState = {
    brands: null,
    error: null,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type){
        case LIST_BRANDS:
            return {
                ...state,
                loading: true
            }
        case LIST_BRANDS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case LIST_BRANDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                brands: action.payload
            }
        default:
            return state;
    }
}