import React, { useState, useEffect } from 'react';

import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
import Loader from '../extras/Loader';
import Order from './Order';

import { ErrorMessage } from '../helpers/ToastMessages';

//Axios
import clienteAxios from '../../config/axios';
import authToken from '../../config/token';
import Cookie from '../../services/CookieService';
//Redux
import { useHistory } from 'react-router';
import { MSG_ERROR_SERVER_CONN } from '../../config/messages';
import { COOKIE_ACCESS_TOKEN } from '../../config/cookies';
//Función principal
const OrderHistory = () => {
    //Inicializo constantes
    const initialLimit = 1;
    //Inicializo el history
    const history = useHistory();
    //Inicializo el state local
    const [orders, setOrders] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ pageLimit, setPageLimit ] = useState(initialLimit);
    //Me fijo si hay cookie
    if(!Cookie.get(COOKIE_ACCESS_TOKEN)){
        //Si no hay redireccion
        history.push('/');
    }
    //Cargo al inicio
    useEffect(() => {
        handleOrders();
    }, [pageLimit]);
    //Handle para traer las ordenes
    const handleOrders = async () => {
        //Hago la consulta
        try{
            //Busco el token de cliente
            const token = Cookie.get(COOKIE_ACCESS_TOKEN);
            if(token){
                //Lo agrego para la consulta
                authToken(token);
                //Obtengo la respuesta
                const response = await clienteAxios.get(`/order/list?start=0&limit=${pageLimit}`);
                //Obtengo los datos
                const res = response.data;
                //Seteo los datos
                setOrders(res.data);   
            }
            //Oculto la espera
            setLoading(false);
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Muestro mensaje
            ErrorMessage(data.message);
        }
    }
    //Manejo el cargar más
    const handleLoadMore = e => {
        //Agrego items a lo actual
        const newLimit = parseInt(pageLimit) + parseInt(initialLimit);
        //Seteo el nuevo límite
        setPageLimit(newLimit);
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                loading ? <Loader /> : (
                    <React.Fragment>
                        <Header />
                        
                        <Breadcrumb title="Mis Pedidos" />

                        <section className="cart-section order-history section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className="table cart-table table-responsive-xs">
                                            <thead>
                                                <tr className="table-head">
                                                    <th scope="col">Producto</th>
                                                    <th scope="col">Descripción</th>
                                                    <th scope="col">Precio</th>
                                                    <th scope="col">Detalle</th>
                                                    <th scope="col">Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orders.map(order => (
                                                        <Order key={`oh${order.id}`} order={order} />
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    orders && orders.length === pageLimit ? (
                                        <div className="row cart-buttons">
                                            <div className="col-12 pull-right">
                                                <button className="btn btn-solid btn-sm" onClick={handleLoadMore}>
                                                    Cargar más pedidos
                                                </button>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </section>

                        <Footer />
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default OrderHistory;