//Auth
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SHOW_REGISTRATION = "SHOW_REGISTRATION";
export const SHOW_REGISTRATION_SUCCESS = "SHOW_REGISTRATION_SUCCESS";
export const SHOW_REGISTRATION_ERROR = "SHOW_REGISTRATION_ERROR";
//Users
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

//Categories
export const LIST_CATEGORIES = "LIST_CATEGORIES";
export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";
export const LIST_CATEGORIES_ERROR = "LIST_CATEGORIES_ERROR";

//Brands
export const LIST_BRANDS = "LIST_BRANDS";
export const LIST_BRANDS_SUCCESS = "LIST_BRANDS_SUCCESS";
export const LIST_BRANDS_ERROR = "LIST_BRANDS_ERROR";

//Products
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const LIST_PRODUCTS_SUCCESS = "LIST_PRODUCTS_SUCCESS";
export const LIST_PRODUCTS_ERROR = "LIST_PRODUCTS_ERROR";

//Cart
export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_ERROR = "REMOVE_FROM_CART_ERROR";
export const LIST_WISHLIST = "LIST_WISHLIST";
export const LIST_WISHLIST_SUCCESS = "LIST_WISHLIST_SUCCESS";
export const LIST_WISHLIST_ERROR = "LIST_WISHLIST_ERROR";
export const ADD_WISHLIST = "ADD_WISHLIST";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const ADD_WISHLIST_ERROR = "ADD_WISHLIST_ERROR";
export const REMOVE_WISHLIST = "REMOVE_WISHLIST";
export const REMOVE_WISHLIST_SUCCESS = "REMOVE_WISHLIST_SUCCESS";
export const REMOVE_WISHLIST_ERROR = "REMOVE_WISHLIST_ERROR";
export const ADD_WISHLIST_TO_CART = "ADD_WISHLIST_TO_CART";
export const ADD_WISHLIST_TO_CART_SUCCESS = "ADD_WISHLIST_TO_CART_SUCCESS";
export const ADD_WISHLIST_TO_CART_ERROR = "ADD_WISHLIST_TO_CART_ERROR";
export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_ERROR = "APPLY_COUPON_ERROR";
export const APPLY_SHIPMENT = "APPLY_SHIPMENT";
export const APPLY_SHIPMENT_SUCCESS = "APPLY_SHIPMENT_SUCCESS";
export const APPLY_SHIPMENT_ERROR = "APPLY_SHIPMENT_ERROR";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_ERROR = "UPDATE_CART_ERROR";

//Order
export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_ERROR = "ADD_ORDER_ERROR";
export const GET_ORDER_PAYMENT = "GET_ORDER_PAYMENT";
export const GET_ORDER_PAYMENT_SUCCESS = "GET_ORDER_PAYMENT_SUCCESS";
export const GET_ORDER_PAYMENT_ERROR = "GET_ORDER_PAYMENT_ERROR";

//ShipmentCots
export const GET_FREE_SHIPPING_LIMIT = "GET_FREE_SHIPPING_LIMIT";
export const GET_FREE_SHIPPING_LIMIT_SUCCESS = "GET_FREE_SHIPPING_LIMIT_SUCCESS";
export const GET_FREE_SHIPPING_LIMIT_ERROR = "GET_FREE_SHIPPING_LIMIT_ERROR";