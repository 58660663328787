import React, { useEffect } from 'react';
import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listWishlistAction, removeWishlistAction, addWishlistToCartAction } from '../../actions/userActions';
import { Link, useHistory } from 'react-router-dom';

//Función principal
const Wishlist = () => {
    //Inicializo el history
    const history = useHistory();
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de cart
    const authUser = useSelector( state => state.user.user );
    const cartLoading = useSelector( state => state.user.cartLoading );
    const cartWishlist = useSelector( state => state.user.wishlist );
    //Debe estar logueado
    if(!authUser){
        //Redirecciono
        history.push('/');
    }
    //Tomo los eventos
    useEffect(() => {
        //Si no esta cargado, busco los datos
        if(!cartWishlist && authUser){
            dispatch( listWishlistAction() );
        }
    }, [cartWishlist, authUser, dispatch]);
    //Función para quitar
    const handleRemove = (e, item) => {
        e.preventDefault();
        //Me fijo que no este procesando
        if(cartLoading){
            return false;
        }
        //Llamo al eliminar
        dispatch( removeWishlistAction(item.product) );
    }
    //Función para mover al carrito
    const handleMoveToCart = (e, item) => {
        e.preventDefault();
        if(cartLoading){
            return false;
        }
        //Llamo a la operación
        dispatch( addWishlistToCartAction(item.product) );
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="Favoritos" />
            <section className="wishlist-section section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table cart-table table-responsive-xs">
                                <thead>
                                <tr className="table-head">
                                    <th scope="col">Imagen</th>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Precio</th>
                                    <th scope="col">Disponibilidad</th>
                                    <th scope="col">Acción</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        cartWishlist ? (
                                            cartWishlist.map(item => (
                                                <tr>
                                                    <td>
                                                        <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                            {
                                                                item.product && item.product.images.length > 0 ? (
                                                                    <img src={ item.product.images[0].img1 } alt="" />
                                                                ) : (
                                                                    <img src="/assets/img/products/default_970.jpg" alt="" />
                                                                )
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                            { item.product.name }
                                                        </Link>
                                                        <div className="mobile-cart-content row">
                                                            <div className="col-xs-3">
                                                                <p>{ item.product.has_stock ? 'En stock' : 'Sin stock'}</p>
                                                            </div>
                                                            <div className="col-xs-3">
                                                                <h2 className="td-color">{ process.env.REACT_APP_CURRENCY_CODE } { item.product.price }</h2></div>
                                                            <div className="col-xs-3">
                                                                <h2 className="td-color">
                                                                    <a href="!#" className="icon mr-1" onClick={e => handleRemove(e, item)}>
                                                                        <i className="ti-close"></i>
                                                                    </a>
                                                                    <a href="!#" className="cart" onClick={e => handleMoveToCart(e, item)}>
                                                                        <i className="ti-shopping-cart"></i>
                                                                    </a>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h2>{ process.env.REACT_APP_CURRENCY_CODE } { item.product.price }</h2></td>
                                                    <td>
                                                        <p>{ item.product.has_stock ? 'En stock' : 'Sin stock'}</p>
                                                    </td>
                                                    <td>
                                                        <a href="!#" className="icon mr-3" onClick={e => handleRemove(e, item)}>
                                                            <i className="ti-close"></i>
                                                        </a>
                                                        <a href="!#" className="cart" onClick={e => handleMoveToCart(e, item)}>
                                                            <i className="ti-shopping-cart"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : null
                                    }
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row cart-buttons">
                        <div className="col-12">
                            <Link to="/mercadito/productos/todos" className="btn btn-solid">
                                Seguir comprando
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};

export default Wishlist;