import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    GET_USER_DATA,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_ERROR,
    ADD_USER,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    SHOW_REGISTRATION,
    SHOW_REGISTRATION_SUCCESS,
    SHOW_REGISTRATION_ERROR,
    LIST_WISHLIST,
    LIST_WISHLIST_SUCCESS,
    LIST_WISHLIST_ERROR,
    ADD_WISHLIST,
    ADD_WISHLIST_SUCCESS,
    ADD_WISHLIST_ERROR,
    GET_CART,
    GET_CART_SUCCESS,
    GET_CART_ERROR,
    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_ERROR,
    REMOVE_FROM_CART,
    REMOVE_FROM_CART_SUCCESS,
    REMOVE_FROM_CART_ERROR,
    REMOVE_WISHLIST,
    REMOVE_WISHLIST_SUCCESS,
    REMOVE_WISHLIST_ERROR,
    ADD_WISHLIST_TO_CART,
    ADD_WISHLIST_TO_CART_SUCCESS,
    ADD_WISHLIST_TO_CART_ERROR,
    APPLY_COUPON,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_ERROR,
    APPLY_SHIPMENT,
    APPLY_SHIPMENT_SUCCESS,
    APPLY_SHIPMENT_ERROR,
    ADD_ORDER,
    ADD_ORDER_SUCCESS,
    ADD_ORDER_ERROR,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_ERROR,
    UPDATE_CART,
    UPDATE_CART_SUCCESS,
    UPDATE_CART_ERROR
} from '../types';

//Cada reducer tiene su propio state
const initialState = {
    error: null,
    loading: false,
    user: null,
    cart: null,
    wishlist: null,
    cartLoading: false
}

export default function(state = initialState, action) {
    switch(action.type){
        case LOGIN:
        case GET_USER_DATA:
        case ADD_USER:
        case EDIT_USER:
        case LOGOUT:
            return {
                ...state,
                loading: true
            }
        case LOGIN_SUCCESS:
        case GET_USER_DATA_SUCCESS:
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload.user,
                cart: action.payload.cart,
                wishlist: action.payload.wishlist
            }
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload
            }
        case ADD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload,
                cart: null, //Al crear un usuario el carro es vacío
                wishlist: null //Al crear un usuario su lista de favoritos es vacía
            }
        case LOGIN_ERROR:
        case GET_USER_DATA_ERROR:
        case ADD_USER_ERROR:
        case EDIT_USER_ERROR:
        case LOGOUT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SHOW_REGISTRATION:
            return {
                ...state,
                loading:true,
                error: null
            }
        case SHOW_REGISTRATION_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case LIST_WISHLIST:
        case ADD_WISHLIST:
        case GET_CART:
        case ADD_TO_CART:
        case REMOVE_FROM_CART:
        case REMOVE_WISHLIST:
        case ADD_WISHLIST_TO_CART:
        case APPLY_COUPON:
        case APPLY_SHIPMENT:
        case ADD_ORDER:
        case UPDATE_PAYMENT_METHOD:
        case UPDATE_CART:
            return {
                ...state,
                cartLoading: true
            }
        case LIST_WISHLIST_ERROR:
        case ADD_WISHLIST_ERROR:
        case GET_CART_ERROR:
        case ADD_TO_CART_ERROR:
        case REMOVE_FROM_CART_ERROR:
        case REMOVE_WISHLIST_ERROR:
        case ADD_WISHLIST_TO_CART_ERROR:
        case APPLY_COUPON_ERROR:
        case APPLY_SHIPMENT_ERROR:
        case ADD_ORDER_ERROR:
        case UPDATE_PAYMENT_METHOD_ERROR:
        case UPDATE_CART_ERROR:
            return {
                ...state,
                cartLoading: false,
                error: action.payload
            }
        case LIST_WISHLIST_SUCCESS:
        case ADD_WISHLIST_SUCCESS:
        case REMOVE_WISHLIST_SUCCESS:
            return {
                ...state,
                cartLoading: false,
                error: null,
                wishlist: action.payload
            }
        case GET_CART_SUCCESS:
        case ADD_TO_CART_SUCCESS:
        case REMOVE_FROM_CART_SUCCESS:
        case APPLY_COUPON_SUCCESS:
        case APPLY_SHIPMENT_SUCCESS:
        case UPDATE_PAYMENT_METHOD_SUCCESS:
        case UPDATE_CART_SUCCESS:
            return {
                ...state,
                cartLoading: false,
                error: null,
                cart: action.payload
            }
        case ADD_WISHLIST_TO_CART_SUCCESS:
            return {
                ...state,
                cartLoading: false,
                error: null,
                cart: action.payload.cart,
                wishlist: action.payload.wishlist
            }
        case ADD_ORDER_SUCCESS:
            return {
                ...state,
                cartLoading: false,
                error: null
            }
        default:
            return state;
    }
}