import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import Footer from '../layout/Footer';
import Breadcrumb from '../layout/Breadcrumb';
import Header from '../layout/Header';
import { Alert } from 'react-bootstrap';
//Mensaje
import { ErrorMessage } from '../helpers/ToastMessages';
//Provincias
import states from '../../config/states';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { addUserAction } from '../../actions/userActions';
//Función principal
const Register = () => {
    //Tomo el history
    const history = useHistory();
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Creo los refs
    const refName = useRef();
    const refLastname = useRef();
    const refEmail = useRef();
    const refPassword = useRef();
    const refStreet = useRef();
    const refNumber = useRef();
    const refCity = useRef();
    const refState = useRef();
    const refZipcode = useRef();
    //Inicializo el state local
    const [ user, setUser ] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone: '',
        street: '',
        number: '',
        floor: '',
        apartment: '',
        city: '',
        state: 'Tucumán',
        zipcode: ''
    })
    //Manejo los cambios
    const onChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    //Traigo el state de auth
    const authLoading = useSelector( state => state.user.loading );
    const authError = useSelector( state => state.user.error );
    //Manejo el submit
    const handleSubmit = e => {
        e.preventDefault();
        //Hago la validación
        if(!user.first_name || user.first_name.trim() === ''){
            ErrorMessage('Debe indicar el nombre');
            refName.current.focus();
            return false;
        }
        //Apellido
        if(!user.last_name || user.last_name.trim() === ''){
            ErrorMessage('Debe indicar el apellido');
            refLastname.current.focus();
            return false;
        }
        //Email
        if(!user.email || user.email.trim() === ''){
            ErrorMessage('Debe indicar el Email');
            refEmail.current.focus();
            return false;
        }
        //Password
        if(!user.password || user.password.trim() === ''){
            ErrorMessage('Debe indicar la Contraseña');
            refPassword.current.focus();
            return false;
        }
        //Calle
        if(!user.street || user.street.trim() === ''){
            ErrorMessage('Debe indicar la Calle');
            refStreet.current.focus();
            return false;
        }
        //Número
        if(!user.number || user.number.trim() === ''){
            ErrorMessage('Debe indicar el número');
            refNumber.current.focus();
            return false;
        }
        //Ciudad
        if(!user.city || user.city.trim() === ''){
            ErrorMessage('Debe indicar la Ciudad');
            refCity.current.focus();
            return false;
        }
        //State
        if(!user.state || user.state.trim() === ''){
            ErrorMessage('Debe indicar la Provincia');
            refState.current.focus();
            return false;
        }
        //Zipcode
        if(!user.zipcode || user.zipcode.trim() === ''){
            ErrorMessage('Debe indicar el Código Postal');
            refZipcode.current.focus();
            return false;
        }
        //Confirmo la clave
        setUser({
            ...user
        })
        //Ejecuto la función
        dispatch( addUserAction(user, history) );
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="Registración" />
            <section className="register-page section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title pt-0">crear cuenta</h3>
                            <div className="theme-card">
                                <form className="theme-form" onSubmit={handleSubmit} autoComplete="off">
                                    <div className="form-row">
                                        {
                                            authError ? (
                                                <div className="col-md-12">
                                                    <Alert variant="danger">
                                                        { authError.data }
                                                    </Alert>
                                                </div>
                                            ) : null
                                        }
                                        <div className="col-md-6">
                                            <label htmlFor="email">Nombres</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="first_name" 
                                                placeholder="Nombre/s" 
                                                required
                                                value={user.first_name}
                                                onChange={onChange}
                                                ref={refName}
                                                autoFocus />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="review">Apellido</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="last_name" 
                                                placeholder="Apellido" 
                                                required
                                                value={user.last_name}
                                                onChange={onChange}
                                                ref={refLastname} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <label htmlFor="email">Email</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Ingrese su email" 
                                                name="email"
                                                required
                                                value={user.email}
                                                onChange={onChange}
                                                ref={refEmail} />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="password">Contraseña</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                name="password"
                                                placeholder="Debe tener más de 6 caracteres"
                                                required
                                                value={user.password}
                                                onChange={onChange}
                                                ref={refPassword} />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="phone">Teléfono</label>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                name="phone" 
                                                placeholder="Teléfono"
                                                value={user.phone}
                                                onChange={onChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="street">Calle</label>
                                            <input 
                                                type="text" 
                                                className="form-control"  
                                                name="street"
                                                placeholder="Ingrese nombre de la calle" 
                                                required
                                                value={user.street}
                                                onChange={onChange}
                                                ref={refStreet} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="number">Número</label>
                                            <input 
                                                type="text" 
                                                className="form-control"  
                                                name="number"
                                                placeholder="" 
                                                required
                                                value={user.number}
                                                onChange={onChange}
                                                ref={refNumber} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="floor">Piso</label>
                                            <input 
                                                type="text" 
                                                className="form-control"  
                                                name="floor"
                                                placeholder="" 
                                                value={user.floor}
                                                onChange={onChange}
                                                />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="apartment">Depto.</label>
                                            <input 
                                                type="text" 
                                                className="form-control"  
                                                name="apartment"
                                                placeholder="" 
                                                value={user.apartment}
                                                onChange={onChange}
                                                />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-5">
                                            <label htmlFor="city">Ciudad</label>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                name="city" 
                                                placeholder="Ciudad" 
                                                required
                                                value={user.city}
                                                onChange={onChange}
                                                ref={refCity} />
                                        </div>
                                        <div className="col-md-5">
                                            <label htmlFor="state">Provincia</label>
                                            {
                                                states.provincias.length > 0 ? (
                                                    <select className="form-control" size="1" name="state" required value={user.state} onChange={onChange} ref={refState}>
                                                        {
                                                            states.provincias.sort((a,b) => (
                                                                a.nombre > b.nombre
                                                            ) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)).map(state => (
                                                                <option value={state.nombre}>
                                                                    { state.nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                ) : (
                                                    <input type="text" name="state" value={user.state} placeholder="Ingrese Provincia" />
                                                )
                                            }
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="zipcode">C.P.</label>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                name="zipcode"
                                                placeholder="Cód. postal" 
                                                required
                                                value={user.zipcode}
                                                onChange={onChange}
                                                ref={refZipcode} />
                                        </div>
                                        <button 
                                            className="btn btn-solid"
                                            type="submit"
                                            disabled={authLoading}
                                        >
                                            Crear cuenta
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};

export default Register;