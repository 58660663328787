import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ItemAddedRelated from './ItemAddedRelated';
import { isMobile } from 'react-device-detect';

const ItemAdded = props => {
    //Tomo los props
    const { show, setShow, product, qty } = props;
    //Manejo el evento de cerrar
    const handleClose = e => {
        e.preventDefault();
        setShow(false);
    }
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Body>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="modal-bg addtocart">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="media mb-5">
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                        {
                                            product && product.images &&  product.images.length > 0 ? (
                                                product.images.map((image, index) => (
                                                    <img key={`pip${ image.id }`} src={ image.img1 } alt="" className={`img-fluid pro-img`} />
                                                ))
                                            ) : (
                                                <img className="img-fluid  pro-img" src="/assets/img/products/default_970.jpg" alt="" />
                                            )
                                        }
                                        </div>
                                        <div className="col-md-8 col-12 mt-2 mt-md-0">
                                            <div className="media-body align-self-center text-center">
                                                <a href="!#">
                                                    <h6>
                                                        <i className="fa fa-check"></i> El producto
                                                        &nbsp;
                                                        <span><strong>{ `${product.name} (${qty})` }</strong></span>
                                                        &nbsp;
                                                        <span> fue agregado correctamente a su Carrito</span>
                                                    </h6>
                                                </a>
                                                <div className="buttons mt-4 text-center">
                                                    <Link to="/carrito" className={`view-cart btn btn-solid ${isMobile ? 'btn-block' : ''}`}>
                                                        Ver carrito
                                                    </Link>
                                                    <Link to="/carrito/checkout" className={`checkout btn btn-solid btn-green ${isMobile ? 'mt-2 btn-block' : 'ml-2'}`}>
                                                        Finalizar
                                                    </Link>
                                                    <Link to="/mercadito/productos" className={`btn btn-solid ${isMobile ? 'mt-2 btn-block' : 'ml-2 mt-2'}`}>
                                                        Continuar comprando
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    product.relateds && product.relateds.length > 0 ? (
                                        <ItemAddedRelated
                                            product={product}
                                        />
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ItemAdded;