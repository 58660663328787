import React from 'react';
import FloatingWhatsApp from 'react-floating-whatsapp';
import { Switch, Route } from 'react-router-dom';
import About from '../components/about/About';
import Cart from '../components/cart/Cart';
import CartCheckout from '../components/cart/CartCheckout';
import CartCheckoutSuccess from '../components/cart/CartCheckoutSuccess';
import Wishlist from '../components/cart/Wishlist';
import ContactUs from '../components/contact/ContactUs';
import Faq from '../components/faq/Faq';
import Home from '../components/home/Home';

import ScrollToTop from '../components/layout/ScrollToTop';
import Loading from '../components/Loading';
import OrderHistory from '../components/orders/OrderHistory';
import ProductDetail from '../components/products/ProductDetail';
import Products from '../components/products/Products';
import ForgotPassword from '../components/user/ForgotPassword';
import LoginRegister from '../components/user/LoginRegister';
import Profile from '../components/user/Profile';
import Register from '../components/user/Register';
import ResetPassword from '../components/user/ResetPassword';

import Notifications from 'react-notify-toast'

const Main = () => {
    return (
        <>
        <Notifications />
        <FloatingWhatsApp
          phoneNumber={ process.env.REACT_APP_WHATSAPP }
          accountName={ process.env.REACT_APP_NAME }
          chatMessage='Hola como podemos ayudarte?'
          statusMessage='Responderemos a la brevedad'
          placeholder='Ingrese su mensaje y presione ENTER'
          className='whatsapp-button'
          allowClickAway
          allowEsc
        />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Loading} />
          <Route exact path="/inicio" component={Home} />
          <Route exact path="/nosotros" component={About} />
          <Route exact path="/preguntas-frecuentes" component={Faq} />
          <Route exact path="/contacto" component={ContactUs} />
          <Route exact path="/iniciar-sesion" component={LoginRegister} />
          <Route exact path="/registracion" component={Register} />
          <Route exact path="/perfil" component={Profile} />
          <Route exact path="/recuperar-contrasena" component={ForgotPassword} />
          <Route exact path="/password/reset/:token" component={ResetPassword} />
          <Route exact path="/mercadito/productos" component={Products} />
          <Route exact path="/mercadito/productos/:query" component={Products} />
          <Route exact path="/mercadito/producto/detalle/:name" component={ProductDetail} />
          <Route exact path="/carrito/checkout/success" component={CartCheckoutSuccess} />
          <Route exact path="/carrito/checkout" component={CartCheckout} />
          <Route exact path="/carrito" component={Cart} />
          <Route exact path="/favoritos" component={Wishlist} />
          <Route exact path="/mis-pedidos" component={OrderHistory} />
        </Switch>
        </>
    );
};

export default Main;