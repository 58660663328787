import {
    LIST_CATEGORIES,
    LIST_CATEGORIES_SUCCESS,
    LIST_CATEGORIES_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import authToken from '../config/token';
import { MSG_ERROR_SERVER_CONN } from '../config/messages';

//LISTAR CATEGORIAS
export function listCategoriesAction() {
    return async (dispatch) => {
        //Inicializo
        dispatch( listCategories() );
        //Ejecuto la consulta
        try{
            //Busco el token de cliente
            const token = process.env.REACT_APP_CLIENT_TOKEN;
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            // const response = await clienteAxios.get('/categories/list');
            const response = await clienteAxios.get('/categories');
            //Obtengo los datos
            const res = response.data;
            //Llamo al dispatch
            dispatch( listCategoriesSuccess(res.data) );
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( listCategoriesError(data) );
        }
    }
}

const listCategories = () => ({
    type: LIST_CATEGORIES,
    payload: true
});

const listCategoriesSuccess = categorys => ({
    type: LIST_CATEGORIES_SUCCESS,
    payload: categorys
});

const listCategoriesError = error => ({
    type: LIST_CATEGORIES_ERROR,
    payload: error
});