import { 
    GET_FREE_SHIPPING_LIMIT, 
    GET_FREE_SHIPPING_LIMIT_ERROR, 
    GET_FREE_SHIPPING_LIMIT_SUCCESS 
} from '../types';
import { MSG_ERROR_SERVER_CONN } from '../config/messages';

import api from '../config/axios';

export function getFreeShippingLimitAction() {
    return async (dispatch) => {
        dispatch( getFreeShippingLimit() );

        try{
            const { data } = await api.get('/shipment-costs/free-shipping');

            dispatch( getFreeShippingLimitSuccess(data.data) );
        }catch(error) {
            const response = error.response;
         
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }

            dispatch( getFreeShippingLimitError(data) );
        }
    }
}

const getFreeShippingLimit = () => ({
    type: GET_FREE_SHIPPING_LIMIT,
    payload: true
});

const getFreeShippingLimitSuccess = shipmentCost => ({
    type: GET_FREE_SHIPPING_LIMIT_SUCCESS,
    payload: shipmentCost
});

const getFreeShippingLimitError = error => ({
    type: GET_FREE_SHIPPING_LIMIT_ERROR,
    payload: error
})