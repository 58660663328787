import { 
    GET_FREE_SHIPPING_LIMIT, 
    GET_FREE_SHIPPING_LIMIT_ERROR, 
    GET_FREE_SHIPPING_LIMIT_SUCCESS 
} from "../types";

const initialState = {
    limit: null,
    error: null,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type){
        case GET_FREE_SHIPPING_LIMIT:
            return {
                ...state,
                loading: true
            }
        case GET_FREE_SHIPPING_LIMIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_FREE_SHIPPING_LIMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                limit: action.payload.free_shipping
            }
        default:
            return state;
    }
}