import React from 'react';
import Swal from 'sweetalert2';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import { useState } from 'react';
import { WaitingMessage, ErrorMessage, SuccessMessage } from '../helpers/ToastMessages';
//Axios
import clienteAxios from '../../config/axios';
import { MSG_ERROR_SERVER, MSG_RESET_PASSWORD_REQUEST, MSG_RESET_PASSWORD_REQUEST_SUCCESS } from '../../config/messages';
//Función principal
const ForgotPassword = () => {
    //Inicializo el state local
    const [ email, setEmail ] = useState('');
    //Manejo el form
    const handleSubmit = async e => {
        e.preventDefault();
        //Valido
        if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) ||/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/.test(email)) {
            //Envío el mail
            try{
                //Cargo la espera
                WaitingMessage(MSG_RESET_PASSWORD_REQUEST);
                //Consulta
                await clienteAxios.post(`/reset-password`, { email });
                //Oculto espesra
                Swal.close();
                //Mensaje
                SuccessMessage(MSG_RESET_PASSWORD_REQUEST_SUCCESS);
                //Limpio los datos
                setEmail('');
            }catch(error){
                const response = error.response;
                let data = {
                    data: null,
                    message: MSG_ERROR_SERVER
                };
                //Verifico si hay error
                if(response){
                    data = response.data;
                }
                //Oculto espesra
                Swal.close();
                //Muestro mensaje
                ErrorMessage(data.message);
            }
        }else{
            ErrorMessage('Debe indicar un E-mail válido');
        }
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="Recuperar contraseña" />
            <section className="pwd-page section-b-space ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 ">
                            <h2 style={{textTransform: 'none'}}>Olvidó su contraseña?</h2>
                            <form className="theme-form" onSubmit={handleSubmit} autoComplete="off">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ingrese su Email" 
                                            required
                                            name="email"
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <button className="btn btn-solid" type="submit">
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};

export default ForgotPassword;