import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

const MainMenu = () => {
    //Inicializo el state local
    const [ showMenu, setShowMenu ] = useState(false);
    //Manejo
    const handleMenu = e => {
        setShowMenu(!showMenu);
    }
    //Renderizo
    return (
        <nav id="main-nav">
            <div className="toggle-nav" onClick={handleMenu}>
                <i className="ti-menu-alt"></i>
            </div>
            <ul id="main-menu" className="sm pixelstrap sm-horizontal" style={ !isBrowser ? { right: `${showMenu ? '0px' : '-410px'}`} : null}>
                <li>
                    <div className="mobile-back text-right" onClick={handleMenu}>
                        Cerrar<i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                    </div>
                </li>
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/nosotros">Nosotros</Link></li>
                <li><Link to="/mercadito/productos">Mercadito</Link></li>
                <li><Link to="/preguntas-frecuentes">Preguntas frecuentes</Link></li>
                <li><Link to="/contacto">Contacto</Link></li>
                <li><a href={ process.env.REACT_APP_LINK_RECETAS } rel="noopener noreferrer" target="_blank">Recetas</a></li>
            </ul>
        </nav>
    );
};

export default MainMenu;