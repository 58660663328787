import {
    LIST_PRODUCTS,
    LIST_PRODUCTS_SUCCESS,
    LIST_PRODUCTS_ERROR
} from '../types';

//Cada reducer tiene su propio state
const initialState = {
    products: null,
    error: null,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type){
        case LIST_PRODUCTS:
            return {
                ...state,
                loading: true
            }
        case LIST_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case LIST_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                products: action.payload
            }
        default:
            return state;
    }
}