import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Loader from '../extras/Loader';
import { ErrorMessage } from '../helpers/ToastMessages';
//Redux
import { useSelector } from 'react-redux';
//Axios
import clienteAxios from '../../config/axios';

//Función principal
const CartCheckoutSuccess = () => {
    //Tomo el history
    const history = useHistory();
    //Tomo los parámetros
    const query = new URLSearchParams(useLocation().search);
    const oid = query.get('oid');
    //Si no hay id, redirecciono
    if(!oid){
        history.push('/');
    }
    //Traigo el store
    const authUser = useSelector(state => state.user.user);
    //Inicializo el state local
    const [ order, setOrder ] = useState(null);
    //Cargo los datos al inicio
    useEffect(() => {
        if(!order){
            loadOrderData();
        }
    }, []);
    //Manejo la carga de la orden
    const loadOrderData = async () => {
        try{
            const data = {
                oid
            }
            //Hago la consulta
            const response = await clienteAxios.post(`/order/checkout/success`, data);
            //Obtengo los datos
            const res = response.data;
            //Seteo los datos
            setOrder(res.data);
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: 'Error al conectarse al servidor'
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Muestro mensaje
            ErrorMessage(data.message);
            //Redirecciono
            history.push('/');
        }
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                order ? (
                    <React.Fragment>
                        <Header />

                        <section className="section-b-space light-layout">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="success-text"><i className="fa fa-check-circle" aria-hidden="true"></i>
                                            <h2>Gracias por tu compra</h2>
                                            {
                                                (order.status === 'ordered') ? (
                                                    <p>Estamos procesando tu pago...</p>
                                                ) : null
                                            }
                                            <p>En pocos minutos recibirás un email con la confirmación de tu compra.</p>
                                            <p>Pedido: { order.number }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="product-order">
                                            <h3 className="title pt-0">Detalle de la orden</h3>
                                            {
                                                order.items.map(item => (
                                                    <div className="row product-order-detail" key={`ccsi${item.id}`}>
                                                        <div className="col-3">
                                                            {
                                                                item.product && item.product.images &&  item.product.images.length > 0 ? (
                                                                    <img src={ item.product.images[0].img1 } className="img-fluid" alt="" />
                                                                ) : (
                                                                    <img src="/assets/img/products/default_900.jpg" alt="" className="img-fluid" />
                                                                )
                                                            }
                                                            
                                                        </div>
                                                        <div className="col-3 order_detail">
                                                            <div>
                                                                <h4>Producto</h4>
                                                                <h5>{ item.name }</h5></div>
                                                        </div>
                                                        <div className="col-3 order_detail">
                                                            <div>
                                                                <h4>Cantidad</h4>
                                                                <h5>{ item.qty_ordered }</h5></div>
                                                        </div>
                                                        <div className="col-3 order_detail">
                                                            <div>
                                                                <h4>Total</h4>
                                                                <h5>{ order.currency_code + ' ' + item.total }</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        
                                            <div className="total-sec">
                                                <ul>
                                                    <li>Subtotal <span>{ order.currency_code + ' ' + order.subtotal }</span></li>
                                                    <li>Descuento <span>{ order.currency_code + ' ' + order.discount_amount }</span></li>
                                                    <li>Desc. p/método pago <span>{ order.currency_code + ' ' + order.payment_discount }</span></li>
                                                    <li>Envío <span>{ order.currency_code + ' ' + order.shipping_total }</span></li>
                                                </ul>
                                            </div>
                                            <div className="final-total">
                                                <h3>total <span>{ order.currency_code + ' ' + order.total }</span></h3></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row order-success-sec">
                                            <div className="col-sm-6">
                                                <h4>Resumen</h4>
                                                <ul className="order-detail">
                                                    <li>ID Orden: { order.number }</li>
                                                    <li>Fecha: { order.created } </li>
                                                    <li>Total orden: { `${order.currency_code} ${order.total}` }</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                {
                                                    order.shipping_method !== 'shipping' ? (
                                                        
                                                        <React.Fragment>
                                                            <h4>Dirección envío</h4>
                                                            {
                                                                order && order.shipping_address[0] ? (
                                                                    <ul className="order-detail">
                                                                        <li>{ order.shipping_address[0].street+' '+order.shipping_address[0].number }</li>
                                                                        <li>{ `${order.shipping_address[0].city}, ${order.shipping_address[0].zipcode}`}</li>
                                                                        <li>{ order.shipping_address[0].state }</li>
                                                                        <li>Teléfono. { order.shipping_address[0].phone }</li>
                                                                    </ul>
                                                                ) : null
                                                            }
                                                            
                                                        </React.Fragment>
                                                    ) : null
                                                }
                                                
                                            </div>
                                            <div className="col-sm-12 payment-mode">
                                                <h4>Método de Pago</h4>
                                                <p>{ order.payment_method }</p>
                                            </div>
                                            {
                                                order.shipping_method === 'shipping' ? (
                                                    <div className="col-md-12">
                                                        <div className="delivery-sec">
                                                            <h3>Fecha de entrega</h3>
                                                            <h2>{ order.delivery_date }</h2></div>
                                                    </div>
                                                ) : null
                                            }
                                            <div className="col-sm-12">
                                                <hr/>
                                            </div>
                                            <div className="col-sm-6">
                                                <Link to="/mercadito/productos" className="btn btn-solid btn-block">
                                                    Seguir comprando
                                                </Link>
                                            </div>
                                            {
                                                authUser ? (
                                                    <div className="col-sm-6">
                                                        <Link to="/mis-pedidos" className="btn btn-solid btn-block btn-green">
                                                            Ir a mis Pedidos
                                                        </Link>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </React.Fragment>
                ) : (
                    <Loader />
                )
            }
        </React.Fragment>
    );
};

export default CartCheckoutSuccess;