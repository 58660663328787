import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import Footer from '../layout/Footer';
import Breadcrumb from '../layout/Breadcrumb';
import Header from '../layout/Header';
import Loader from '../extras/Loader';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { applyCouponAction, addOrderAction, updateCartAction } from '../../actions/userActions';

//Provincias
import states from '../../config/states';
import Swal from 'sweetalert2';
//Payment methods
import { STORE_PAYMENT_NAME, ONLINE_PAYMENT_NAME, TRANSFER_PAYMENT_NAME } from '../../data/paymentMethods';
import paymentMethods from '../../data/paymentMethods';
import { SHIPPING_METHOD_PICKUP, SHIPPING_METHOD_SHIPPING } from '../../data/shippingMethods';

import { ErrorMessage } from '../../components/helpers/ToastMessages';
import FreeShippingAlert from '../extras/FreeShippingAlert';

//Renderizo
const CartCheckout = () => {
    //Inicializo el dispatch
    const dispatch = useDispatch();
    const refZipcode = useRef();
    //Tomo el history
    const history = useHistory();
    //Inicializo info inicial
    const initalInfo = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        street: '',
        number: '',
        floor: '',
        apartment: '',
        city: '',
        state: 'Tucumán',
        zipcode: '4000'
    };
    //Traigo el store de carrito
    const cart = useSelector( state => state.user.cart );
    const cartLoading = useSelector( state => state.user.cartLoading );
    //Traigo el store de usuario
    const authUser = useSelector( state => state.user.user );
    //Inicializo el state local
    const [shipment, setShipment] = useState( cart && cart.shipping_method ? cart.shipping_method : SHIPPING_METHOD_PICKUP);
    const [code, setCode] = useState('');
    const [info, setInfo] = useState(initalInfo);
    const [paymentMethod, setPaymentMethod] = useState();
    //Al inicio cargo los datos
    useEffect(() => {
        setInfoData();
    }, [cart]);

    const setInfoData = () => {
        if(cart){
            setInfo({
                first_name: cart.shipping_name,
                last_name: cart.shipping_lastname,
                phone: cart.shipping_phone,
                email: cart.shipping_email,
                street: cart.shipping_street,
                number: cart.shipping_number,
                floor: cart.shipping_floor,
                apartment: cart.shipping_apartment,
                city: cart.shipping_city,
                state: cart.shipping_state ? cart.shipping_state : initalInfo.state,
                zipcode: cart.shipping_zipcode ? cart.shipping_zipcode : initalInfo.zipcode
            })
        }
    }

    //Manejo los cambios
    const onChangeShipment = e => {
        //Verifico que tenga zipcode
        if(e.target.value === SHIPPING_METHOD_SHIPPING){
            //Verifico
            if(info.zipcode.trim() === ''){
                //Mensaje
                ErrorMessage("Debe indicar el código Postal");
                //Foco
                refZipcode.current.focus();
                return false;
            }
        }
        //Seteo el valor
        setShipment(e.target.value);
        
        updateShipmentCost(e.target.value, info.zipcode);
    }
    const updateShipmentCost = (shipment, zipcode) => {
        const zipCode = zipcode || initalInfo.zipcode;

        const data = {
            ...cart,
            ...info,
            shipping_method: shipment,
            zipcode: zipCode
        }
        //Llamo a la acción
        dispatch( updateCartAction(data) );
    }
    const onChangeCode = e => {
        setCode(e.target.value);
    }
    const onChangeInfo = e => {
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    }
    const onChangePayment = async e => {
        setPaymentMethod(e.target.value);

        const data = {
            ...cart,
            ...info,
            payment_method: e.target.value
        }

        dispatch( updateCartAction(data) );
    }

    //Aplicar cupón
    const handleApplyCoupon = e => {
        e.preventDefault();
        //Verifico el valor del código
        if(code.trim() === ''){
            return false;
        }
        //Armo la data
        const data = {
            code
        }
        //Debo llamar a la acción
        dispatch( applyCouponAction(data) );
        //Limpio los datos
        setCode('');
    }
    //Usar datos de cuenta
    const handleUseAccount = e => {
        //Me fijo si usa los datos
        if(e.target.checked){
            if(authUser){
                
                const data = {
                    ...cart,
                    ...authUser.account,
                    shipping_method: shipment,
                }
                //Llamo a la acción
                dispatch( updateCartAction(data) );
            }
        }else{
            //setInfo(initalInfo);

            const data = {
                ...cart,
                info: initalInfo,
                shipping_method: shipment,
            }
            //Llamo a la acción
            dispatch( updateCartAction(data) );

            //updateShipmentCost(shipment, initalInfo.zipcode);
        }
    }
    //Handle finalizar orden
    const handleSubmit = e => {
        e.preventDefault();
        //Parseo el método de pago
        const pm = cart.payment_method;
        //Debo validar que haya seleccionado un método de pago
        if(pm !== STORE_PAYMENT_NAME && pm !== ONLINE_PAYMENT_NAME && pm !== TRANSFER_PAYMENT_NAME){
            ErrorMessage('Debe seleccionar un método de pago');
            //Mensaje
            return false;
        }
        
        //Genero los datos
        /*const dataOrder = {
            cart_id: cart.id,
            first_name: info.first_name,
            last_name: info.last_name,
            phone: info.phone,
            email: info.email,
            street: info.street,
            number: info.number,
            floor: info.floor,
            apartment: info.apartment,
            city: info.city,
            state: info.state,
            zipcode: info.zipcode,
            payment_method: paymentMethods[pm],
            shipping_method: shipment
        }*/

        const dataOrder = {
            ...cart,
            ...info,
            cart_id: cart.id
        }

        //Cargo la espera
        Swal.fire({
            title: 'Procesando información',
            text: 'Por favor aguarde.',
            onBeforeOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false
        });
        //Envío los datos
        dispatch( addOrderAction(dataOrder, history) );
    }

    const onBlurZipCode = e => {
        //Debo recalcular el código postal
        updateShipmentCost(shipment, e.target.value);
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                !cart && cartLoading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <Header />
                        <Breadcrumb title="Finalizar compra" />
                        
                        <section className="section-b-space">
                            <div className="container">
                                <div className="checkout-page">
                                    <div className="checkout-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                                    <div className="checkout-title">
                                                        <h3>Datos de Contacto / Envío</h3>
                                                    </div>
                                                    {
                                                        authUser && authUser.account ? (
                                                            <div className="alert alert-warning">
                                                                <h4 style={{textTransform:'none'}}>
                                                                    <small>Ud. se encuentra logueado como</small>&nbsp;
                                                                    <strong>{ authUser.account.email }</strong>
                                                                </h4>
                                                                <input type="checkbox" name="user-account-data" onClick={handleUseAccount} /> &ensp;
                                                                <label htmlFor="account-option">Usar datos de la cuenta?</label>
                                                            </div>
                                                        ) : null
                                                    }
                                                    
                                                    <div className="row check-out">
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Nombre</div>
                                                            <input 
                                                                type="text" 
                                                                name="first_name" 
                                                                value={info.first_name || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Apellido</div>
                                                            <input 
                                                                type="text" 
                                                                name="last_name" 
                                                                value={info.last_name || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Teléfono</div>
                                                            <input 
                                                                type="text" 
                                                                name="phone" 
                                                                value={info.phone || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Email</div>
                                                            <input 
                                                                type="text" 
                                                                name="email" 
                                                                value={info.email || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">Calle</div>
                                                            <input 
                                                                type="text" 
                                                                name="street" 
                                                                value={info.street || ''}
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                                            <div className="field-label">Número</div>
                                                            <input 
                                                                type="text" 
                                                                name="number" 
                                                                value={info.number || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                                            <div className="field-label">Piso</div>
                                                            <input 
                                                                type="text" 
                                                                name="floor" 
                                                                value={info.floor || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo} />
                                                        </div>
                                                        <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                                            <div className="field-label">Depto.</div>
                                                            <input 
                                                                type="text" 
                                                                name="apartment" 
                                                                value={info.apartment || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo} />
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">Ciudad</div>
                                                            <input 
                                                                type="text" 
                                                                name="city" 
                                                                value={info.city || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                required />
                                                        </div>
                                                        <div className="form-group col-md-8 col-sm-6 col-xs-12">
                                                            <div className="field-label">Provincia</div>
                                                            {
                                                                states.provincias.length > 0 ? (
                                                                    <select className="" name="state" value={info.state} onChange={onChangeInfo}>
                                                                        {
                                                                            states.provincias.sort((a,b) => (
                                                                                a.nombre > b.nombre
                                                                            ) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)).map((state, index) => (
                                                                                <option value={state.nombre} key={`sl${index}`}>
                                                                                    { state.nombre}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                ) : (
                                                                    <input type="text" name="state" value="" placeholder="" />
                                                                )
                                                            }
                                                            
                                                        </div>
                                                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                                            <div className="field-label">Código Postal</div>
                                                            <input 
                                                                ref={refZipcode}
                                                                type="text" 
                                                                name="zipcode" 
                                                                value={info.zipcode || ''} 
                                                                placeholder=""
                                                                onChange={onChangeInfo}
                                                                onBlur={onBlurZipCode}
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                                    <div className="checkout-details">
                                                        <div className="order-box">
                                                            <div className="title-box">
                                                                <div>Producto <span>Total</span></div>
                                                            </div>
                                                            <ul className="qty">
                                                                {
                                                                    cart && cart.items && cart.items.map(item => (
                                                                        <li key={`ci${item.id}`}>
                                                                            { item.name } × { item.qty} <span>${item.price}</span>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                            <ul className="sub-total">
                                                                <li>Subtotal <span className="count">$ <b>{ cart && cart.subtotal }</b></span></li>
                                                                <li>
                                                                    <div className='form-group col-xs-12'>
                                                                        <label>Método de pago</label>
                                                                        <select name="payment_method" onChange={onChangePayment} value={cart && (cart.payment_method == null ? '' : cart.payment_method)}>
                                                                            <option value="">Seleccione Método de Pago</option>
                                                                            <option value={ STORE_PAYMENT_NAME }>
                                                                                Efectivo { process.env.REACT_APP_CASH_DISCOUNT }
                                                                            </option>
                                                                            <option value={ TRANSFER_PAYMENT_NAME }>Transferencia</option>
                                                                            <option value={ ONLINE_PAYMENT_NAME }>MercadoPago</option>
                                                                        </select>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                <div className="col-xs-12">
                                                                    <div className='row'>
                                                                        <div className="form-group col-md-6 col-xs-12">
                                                                            <input 
                                                                                type="text" 
                                                                                name="coupon_code" 
                                                                                value={code} 
                                                                                placeholder="Código" 
                                                                                autoComplete="off"
                                                                                onChange={onChangeCode} />
                                                                        </div>
                                                                        <div className="form-group col-md-6 col-xs-12">
                                                                            <button type="button" className="btn-solid btn btn-block" onClick={handleApplyCoupon}>
                                                                                Aplicar cupón
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </li>
                                                                
                                                            </ul>
                                                            <ul className="sub-total">
                                                                <li>
                                                                    Descuentos 
                                                                    <span className="count">
                                                                        $ { cart && cart.total_discount }
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Desc. p/método pago
                                                                    <span className="count">
                                                                        $ { cart && cart.payment_discount }
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <strong>Subtotal</strong>
                                                                    <span className="count">
                                                                        $ <b>{ cart && (cart.subtotal - cart.total_discount - cart.payment_discount).toFixed(2) }</b>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                            
                                                            <FreeShippingAlert cart={cart} />
                                                            
                                                            <ul className="total">
                                                                <li>
                                                                    <div className="form-group col-xs-12">
                                                                        <div className="shipping-option">
                                                                            <label>Tipo de entrega</label>
                                                                            <select name="delivery" onChange={onChangeShipment} value={cart && (cart.shipping_method == null ? '' : cart.shipping_method)}>
                                                                                <option value="">Seleccione tipo de entrega</option>
                                                                                <option value={ SHIPPING_METHOD_SHIPPING }>Envío a domicilio</option>
                                                                                <option value={ SHIPPING_METHOD_PICKUP }>A convenir</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    Costo envío 
                                                                    <span className="count">
                                                                        $ { cart && cart.shipping_amount }
                                                                    </span>
                                                                </li>
                                                                
                                                                <li>
                                                                    <strong>Total</strong> 
                                                                    <span className="count">
                                                                        $ <b>{ cart && cart.total }</b>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="payment-box">
                                                            <div className="text-right">
                                                                <button type="submit" className="btn btn-solid">
                                                                    Continuar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default CartCheckout;