import React, { useState } from 'react';
import QuickView from './QuickView';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { addWishlistAction, addToCartAction } from '../../actions/userActions';
import { Link } from 'react-router-dom';
//Función principal
const BoxHome = props => {
    //Tomo los props
    const { product } = props;
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo del store
    const authUser = useSelector( state => state.user.user );
    //Inicializo el state local
    const [ showQuickView, setShowQuickView ] = useState(false);
    const [ qty, setQty ] = useState(1);
    //Manejo el add to wishlist
    const handleAddWishlist = item => {
        if(item && authUser){
            dispatch(addWishlistAction(item));
        }
    }
    //Manejo la vista rapida
    const handleQuickView = (e, show) => {
        //Me fijo si hay evento
        if(e){
            e.preventDefault();
        }
        //Modifico
        setShowQuickView(show);
    }
    //Manejo la cantidad
    const handleChange = e => {
        let v = e.target.value;
        if(isNaN(v) || v < 1){
            v = 1;
        }
        //Seteo el valor
        setQty(v);
    }
    //Manejo el agregar al carrito
    const handleAddtoCart = product => {
        //Genero los datos
        const data = {
            product_id: product.id,
            qty
        }
        //Hago el dispatch
        dispatch( addToCartAction(data) );
        //Una vez agregado, reinicio
        setQty(1);
    }
    //Renderizo
    return (
        <div className="col-lg-2 col-sm-4 col-6 p-0">
            <div className="product-box product-select-box">
                <div className="img-block">
                    <a href="!#" onClick={e => handleQuickView(e, true)}>
                        {
                            product.images.length > 0 ? (
                                <img src={ product.images[0].img0 } className=" img-fluid bg-img" alt="" />
                            ) : (
                                <img src="/assets/img/products/default_900.jpg" className=" img-fluid bg-img" alt="" />
                            )
                        }
                    </a>
                    {
                        product.has_discount ? (
                            <div class="lable-wrapper">
                                <span class="lable3">Oferta</span>
                            </div>
                        ) : null
                    }
                    <div className="cart-details">
                        {
                            authUser ? (
                                <button title="Agregar a Favoritos" onClick={() => handleAddWishlist(product)}>
                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                </button>
                            ) : null
                        }

                        <a href="!#" title="Vista rápida" onClick={e => handleQuickView(e, true)}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </a>

                        <QuickView 
                            show={showQuickView} 
                            handleClose={(e, show) => handleQuickView(e, show)} 
                            product={product}
                        />
                    </div>
                </div>
                <div className="product-info">
                    <Link to={`/mercadito/producto/detalle/${product.slug}`}>
                        <h6>{ product.name }</h6>
                    </Link>
                    <h5>
                        ${ product.price } 
                        {
                            product.has_discount ? (
                                <>
                                <small className="ml-1"><strike>${ product.original_price }</strike></small>
                                </>
                            ) : null
                        }
                    </h5>
                </div>
                <hr/>
                <div className="qty-add-box">
                    <div className="qty-box">
                        <label>Cant.:</label>
                        <div className="input-group">
                            <input 
                                type="number" 
                                name="quantity" 
                                className="form-control input-number" 
                                value={qty} 
                                onChange={handleChange} />
                        </div>
                        <button  title="Agregar" onClick={() => handleAddtoCart(product)}>                            
                            <span className="ti-shopping-cart"></span>
                            <i className="ti-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoxHome;