import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
//Mensajes
import { ErrorMessage } from '../helpers/ToastMessages';
//Función principal
const FormSearch = () => {
    //Traigo el history
    const history = useHistory();
    //Inicializo el state local
    const [ search, setSearch ] = useState('');
    const [ loading, setLoading ] = useState(false);
    //Manejo los cambios
    const onChange = e => {
        setSearch(e.target.value);
    }
    //Manejo de la búsqueda
    const handleSearch = e => {
        e.preventDefault();
        //Seteo la esspera
        setLoading(true);
        //Valido
        if(!search || search.trim() === ''){
            ErrorMessage('Debe indicar el texto a buscar');
            return false;
        }
        //Redirecciono al listado de productos
        history.push(`/mercadito/productos/search?name=${search}`);
        //Vuelvo a habilitar
        setLoading(false);
    }
    //Renderizo
    return (
        <form onSubmit={handleSearch} autoComplete="off">
            <div className={ isMobile ? 'col-xl-12' : ''}>
                <input 
                    className={ isMobile ? 'form-control' : 'search__input'}
                    type="text" 
                    placeholder="Buscar un producto"
                    name="search"
                    value={search}
                    onChange={onChange}
                    />
                <button className={ isMobile ? 'btn btn-primary' : 'search-icon'} disabled={loading}>
                    { 
                        isMobile ? (
                            <i class="fa fa-search"></i>
                        ) : null
                    }
                </button>
            </div>
        </form>
    );
};

export default FormSearch;