import React from 'react';
import { Link } from 'react-router-dom';

const Order = props => {
    const { order } = props;

    return (
        order.items.map( item => (
            <tr>
                <td>
                    <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                        {
                            item.product && item.product.images.length > 0 ? (
                                <img src={ item.product.images[0].img1 } alt="" />
                            ) : (
                                <img src="/assets/img/products/default_970.jpg" alt="" />
                            )
                        }
                    </Link>
                </td>
                <td>
                    <span className="dark-data">
                        { item.product.name }
                    </span>
                    <br/>
                    <span>Cantidad: { item.qty }</span>
                </td>
                <td>
                    <h4>{ `${order.currency_code} ${item.price}` }</h4>
                </td>
                <td>
                    Orden No: <span className="dark-data">{ order.number }</span>
                    <br />
                    Total: <span className="dark-data">{ `${order.currency_code} ${order.total}` }</span>
                    <div className="mobile-cart-content row">
                        <div className="col-xs-3">
                            <div className="qty-box">
                                <div className="input-group">
                                    <input type="text" name="quantity" className="form-control input-number" value={ order.qty_ordered } />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-3">
                            <h4 className="td-color">
                                { `${order.currency_code} ${item.price}` }
                            </h4>
                        </div>
                        <div className="col-xs-3">
                            <h2 className="td-color">
                                <a href="!#" className="icon"><i className="ti-close"></i></a>
                            </h2>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="responsive-data">
                        <h4 className="price">{ `${order.currency_code} ${item.price}` }</h4>
                        <span>Cantidad: { item.qty_ordered }</span>
                    </div>
                    <span className="dark-data">{ order.status }</span>
                    <br/>
                    ({ order.formated_date })
                </td>
            </tr>
        ))
    );
};

export default Order;