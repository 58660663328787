import {
    LIST_BRANDS,
    LIST_BRANDS_SUCCESS,
    LIST_BRANDS_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import authToken from '../config/token';
import { MSG_ERROR_SERVER_CONN } from '../config/messages';

//LISTAR CATEGORIAS
export function listBrandsAction() {
    return async (dispatch) => {
        //Inicializo
        dispatch( listBrands() );
        //Ejecuto la consulta
        try{
            //Busco el token de cliente
            const token = process.env.REACT_APP_CLIENT_TOKEN;
            //Lo agrego para la consulta
            authToken(token);
            //Hago la consulta
            const response = await clienteAxios.get('/brands/list');
            //Obtengo los datos
            const res = response.data;
            //Llamo al dispatch
            dispatch( listBrandsSuccess(res.data) );
        }catch(error){
            const response = error.response;
            let data = {
                data: null,
                message: MSG_ERROR_SERVER_CONN
            };
            //Verifico si hay error
            if(response){
                data = response.data;
            }
            //Llamo al dispatch
            dispatch( listBrandsError(data) );
        }
    }
}

const listBrands = () => ({
    type: LIST_BRANDS,
    payload: true
});

const listBrandsSuccess = brands => ({
    type: LIST_BRANDS_SUCCESS,
    payload: brands
});

const listBrandsError = error => ({
    type: LIST_BRANDS_ERROR,
    payload: error
});