import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UnsplashBadge from '../extras/UnsplashBadge';
//Función principal
const Footer = () => {
    const freeShipping = useSelector( state => state.freeShipping.limit );

    //Seteo del slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 10,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    }
    //Imágenes instagram
    const instagramImages = [
        'B_4g8k4jtcs',
        'B_wyebojJCP',
        'B_kowE1j6x8',
        'B_S9KJbhCeA',
        'B_M6oHQjvDJ',
        'B_BTX8PBqv_',
        'B-2vaAOhcXT',
        'B-0WRQDh8my',
        'B-um-MSBe6n',
        'B-srjqqBTgs'
    ];
    //Renderizo
    return (
        <footer className="footer-3">
            <div className="subscribe-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="subscribe-content">
                                <h4> <i className="fa fa-truck" aria-hidden="true"></i>Envíos sin cargo <small>a partir de ${ freeShipping ? freeShipping : '-' }</small></h4>
                                <p>Sólo para San Miguel de Tucumán, Yerba Buena y Tafí Viejo. Por otra localidad consultar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="instagram section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="instagram-banner">
                                <h5>Seguínos <a href="https://www.instagram.com/cocinarte.estudio"><span>@cocinarte.estudio</span></a></h5>
                            </div>
                            <Slider {...settings} className="slide-10 no-arrow slick-instagram">
                                {
                                    instagramImages.map((i, index) => (
                                        <div key={`fii${i}`}>
                                            <a href={`https://www.instagram.com/p/${i}`}>
                                                <div className="instagram-box">
                                                    <img src={`/assets/img/instagram/${index + 1}.jpg`} alt="" className="img-fluid" />
                                                    <div className="overlay">
                                                        <i className="fa fa-instagram"  aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <div className="footer-section">
                <div className="container">
                    <div className="row border-cls section-b-space section-t-space">
                        <div className="col-xl-6 col-lg-12 about-section">
                            <div className="footer-title footer-mobile-title">
                                <h4>Quienes somos</h4>
                            </div>
                            <div className="footer-content">
                                <div className="footer-logo">
                                    <img src="/assets/img/logo/logo-footer.png" alt="" height="40" />
                                </div>
                                <p>
                                Inspirarlos a elegir mejor la materia prima  , ayudarlos en el proceso y así ganar salud y bienestar , no sólo físico sino también mental y espiritual 💛
                                </p>
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/cocinarte.estudio/" rel="noopener noreferrer" target="_blank">
                                                <i className="fa fa-facebook" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://ar.pinterest.com/cocinarteyb/" rel="noopener noreferrer" target="_blank">
                                                <i className="fa fa-pinterest" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/cocinarte.estudio/" rel="noopener noreferrer" target="_blank">
                                                <i className="fa fa-instagram" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://cocinarte-estudio.com/" rel="noopener noreferrer" target="_blank">
                                                <i className="fa fa-rss" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                            <div className="row height-cls">
                                <div className="col-lg-5 footer-link">
                                    <div>
                                        <div className="footer-title">
                                            <h4>Mi cuenta</h4>
                                        </div>
                                        <div className="footer-content">
                                            <ul>
                                                <li>
                                                    <Link to="/perfil">
                                                        Perfil
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/carrito">
                                                        Mi carrito
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/favoritos">
                                                        Favoritos
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/registracion">
                                                        Registro
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 footer-link">
                                    <div>
                                        <div className="footer-title">
                                            <h4>Contactanos</h4>
                                        </div>
                                        <div className="footer-content">
                                            <ul className="contact-list">
                                                <li><i className="fa fa-map-marker"></i>Yerba Buena, Tucumán</li>
                                                <li><i className="fa fa-phone"></i>Whatsapp: 3816735590</li>
                                                <li><i className="fa fa-envelope-o"></i>Email: info@cocinarte-mercadito.com.ar</li>
                                                <li><i className="fa fa-truck"></i>Envíos a todo Tucumán</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="footer-bottom">
                                <ul>
                                    <li>
                                        <Link to="/nosotros">
                                            Nosotros
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/mercadito/productos">
                                            Mercadito
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contacto">
                                            Contacto
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12 mb-xs-2">
                            <div className="footer-end">
                                <p><i className="fa fa-copyright" aria-hidden="true"></i>  2020 <span style={{ textTransform: 'none' }}>Desarrollado por</span>  <a href="https://developia.com.ar/" rel="noopener noreferrer" target="_blank" title="Developia"><img src="/assets/img/logo/developia.png" alt="" className="ml-2" /></a></p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12">
                            <div className="payment-card-bottom">
                                <ul>
                                    <li>
                                        <UnsplashBadge 
                                            url="https://unsplash.com/@brookelark?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                                            name="Brooke Lark"
                                        />
                                    </li>
                                    <li>
                                        <a href="https://mercadopago.com.ar/" rel="noopener noreferrer" target="_blank">
                                            <img src="/assets/img/logo/mercadopago.png" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;