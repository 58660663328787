import React, { useState } from 'react';
//Redux
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
//Función principal
const LeftNav = props => {
    //Traigo el history
    const history = useHistory();
    //Inicializo el state local
    const [ showMenu, setShowMenu ] = useState(false);
    //Tomo el state
    const categories = useSelector( state => state.categories.categories );
    //Maneja menu
    const handleMenu = e => {
        e.preventDefault();
        setShowMenu(!showMenu);
    }
    //Manejo de filtro
    const handleFilter = (e, category) => {
        e.preventDefault();
        //Redirecciono al listado de productos
        history.push(`/mercadito/productos/search?category=${category.id}`);
        setShowMenu(false);
    }
    //Renderizo
    return (
        <div className="navbar">
            <a href="!#" onClick={handleMenu}>
                <div className="bar-style"><i className="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
            </a>
            <div id="mySidenav" className={`sidenav ${showMenu ? 'open-side' : null}`}>
                <button className="sidebar-overlay" onClick={handleMenu}></button>
                <nav className="">
                    <div onClick={handleMenu}>
                        <div className="sidebar-back text-left">
                            <i className="fa fa-angle-left pr-2" aria-hidden="true"></i> Volver
                        </div>
                    </div>
                    <ul id="sub-menu" className="sm pixelstrap sm-vertical ">
                        {
                            categories ? (
                                categories.map(category => (
                                    <li key={`clm${category.id}`}>
                                        <a href="!#" onClick={e => handleFilter(e, category)}>
                                            { category.name }
                                        </a>
                                    </li>
                                ))
                            ) : null
                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default LeftNav;