export const MSG_LOGIN_IN = 'Iniciando sesión';
export const MSG_LOGIN_OUT = 'Cerrando sesión';
export const MSG_REGISTERING_USER = 'Registrando usuario';
export const MSG_UPDATING_USER = 'Actualizando usuario';
export const MSG_PLEASE_WAIT = 'Por favor aguarde';
export const MSG_EDIT_USER_SUCCESS = 'Datos actualizados';
export const MSG_RESET_PASSWORD_REQUEST = 'Enviando solicitud';
export const MSG_RESET_PASSWORD_REQUEST_SUCCESS = 'Su solicitud fue enviada correctamente, revise su casilla de correo para continuar.';
export const MSG_RESETING_PASSWORD = 'Reseteando contraseña';
export const MSG_RESET_PASSWORD_SUCCESS = 'Su contraseña fue modificada correctamente. Inicie sesión.';

export const MSG_CART_ADDING_ITEM = 'Agregando ítem';
export const MSG_CART_ADD_ITEM_SUCCESS = 'Ítem agregado correctamente';
export const MSG_CART_REMOVE_ITEM_SUCCESS = 'Ítem eliminado correctamente';
export const MSG_CART_UPDATE_SUCCESS = 'Carro actualizado correctamente';
export const MSG_CART_COUPON_ADD_SUCCESS = 'Cupón agregado correctamente';
export const MSG_CART_UPDATING = 'Actualizando información';

export const MSG_WISHLIST_REMOVING = 'Quitando de favoritos';
export const MSG_WISHLIST_ADD_SUCCESS = 'Agregado a favoritos';
export const MSG_WISHLIST_TO_CART = 'Procesando';

//Error messages
export const MSG_ERROR_SERVER = 'Error del Servidor';
export const MSG_ERROR_SERVER_CONN = 'Error al conectarse al servidor. Reintente.';
export const MSG_ERROR_WISHLIST_NOT_USER = 'Debe estar logueado';