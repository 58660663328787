import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//Redux
import { useDispatch } from 'react-redux';
import { addToCartAction } from '../../actions/userActions';
//Función principal
const QuickView = props => {
    //Tomo los props
    const { show, handleClose, product } = props;
    //Inicializo el state local
    const [ qty, setQty ] = useState(1);
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Manejo el cambio de valor
    const onChange = e => {
        setQty(e.target.value);
    }
    //Agregar al carrito
    const handleAddtoCart = e => {
        e.preventDefault();
        //Genero los datos
        const data = {
            product_id: product.id,
            qty
        }
        //Hago el dispatch
        dispatch( addToCartAction(data) );
        //Una vez agregado, reinicio
        setQty(1);
        //Cierro el overlay
        handleClose(e, false);
    }
    //Renderizo
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Body>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => handleClose(e, false)}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="row">
                    <div className="col-lg-6 col-xs-12">
                        <div className="quick-view-img">
                            {
                                product.images.length > 0 ? (
                                    <img src={ product.images[0].img1 } className="img-fluid" alt="" />
                                ) : (
                                    <img src="/assets/img/products/default_970.jpg" alt="" className="img-fluid " />
                                )
                            }
                            
                        </div>
                    </div>
                    <div className="col-lg-6 rtl-text">
                        <div className="product-right">
                            <h2>
                                { product.name }
                                <br/>
                                <small>
                                    { product.brand && product.brand.name } <small>({ product.category && product.category.name })</small>
                                </small>
                            </h2>
                            {
                                product.has_discount ? (
                                    <h4><del>${ product.original_price }</del><span>{ product.discount }% DESC.</span></h4>
                                ) : null
                            }
                            
                            <h3>${ product.special_price ? product.special_price : product.price }</h3>
                            <div className="border-product">
                                <h6 className="product-title">Descripción:</h6>
                                <p>{ product.description }</p>
                            </div>
                            <div className="product-description border-product">
                                <h6 className="product-title">Cantidad:</h6>
                                <div className="qty-box">
                                    <div className="input-group">
                                    <input 
                                        type="number" 
                                        name="qty" 
                                        className="form-control input-number" 
                                        value={qty} 
                                        autoComplete="off"
                                        onChange={onChange}
                                        style={{ width: '100px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="product-buttons">
                                <a href="!#" className="btn btn-solid btn-green" onClick={e => handleAddtoCart(e, product)}>
                                    Agregar al carrito
                                </a>
                                <Link className="btn btn-solid mt-2 mt-md-0" to={`/mercadito/producto/detalle/${product.slug}`}>
                                    Ver más
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default QuickView;