import React from 'react';
import { Link } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeWishlistAction } from '../../actions/userActions';
//Función principal
const WishlistPanel = props => {
    //Tomo los props
    const { showWishlist, handleWishlist } = props;
    //Inicializo variable
    let total = 0;
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store de cart
    const cartLoading = useSelector( state => state.user.cartLoading );
    const cartWishlist = useSelector( state => state.user.wishlist );
    //Tomo los eventos
    // useEffect(() => {
    //     //Si no esta cargado, busco los datos
    //     if(!cartWishlist){
    //         dispatch( listWishlistAction() );
    //     }
    // }, [cartWishlist, dispatch]);
    //Función para quitar
    const handleRemove = (e, item) => {
        e.preventDefault();
        //Me fijo que no este procesando
        if(cartLoading){
            return false;
        }
        //Llamo al eliminar
        dispatch( removeWishlistAction(item.product) );
    }
    //Renderizo
    return (
        <div id="wishlist_side" className={`add_to_cart right ${showWishlist ? 'open-side' : null}`}>
            <a href="!#" className="overlay" onClick={handleWishlist}></a>
            <div className="cart-inner">
                <div className="cart_top">
                    <h3>Favoritos</h3>
                    <div className="close-cart">
                        <a href="!#" onClick={handleWishlist}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
                <div className="cart_media">
                    {
                        cartWishlist ? (
                            <ul className="cart_product">
                                {
                                    cartWishlist.map(item => {
                                        //Calculo el total
                                        total += parseFloat(item.product.price);
                                        //Renderizo
                                        return (
                                        <li key={`wpi${item.id}`}>
                                            <div className="media">
                                                <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                    {
                                                        item.product && item.product.images.length > 0 ? (
                                                            <img src={ item.product.images[0].img1 } className="mr-3" alt="" />
                                                        ) : (
                                                            <img alt="" className="mr-3" src="/assets/img/products/default_970.jpg" />
                                                        )
                                                    }
                                                </Link>
                                                <div className="media-body">
                                                    <Link to={`/mercadito/producto/detalle/${item.product.slug}`}>
                                                        <h5>{ item.product.name }</h5>
                                                    </Link>
                                                    <h4>
                                                        <span>$ { item.product.price }</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="close-circle">
                                                <a href="!#" onClick={e => handleRemove(e, item)}>
                                                    <i className="ti-trash" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </li>
                                    )})
                                }
                            </ul>
                        ) : null
                    }
                    <ul className="cart_total">
                        <li>
                            <div className="total">
                                <h5>
                                    Subtotal: <span>$ {total.toFixed(2)}</span>
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div className="buttons">
                                <Link to="/favoritos" className="btn btn-solid btn-block btn-solid-sm view-cart">
                                    Ver lista completa
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default WishlistPanel;