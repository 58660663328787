import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    //Tomo el pathname
    const { pathname } = useLocation();
    //Al inicio
    useEffect(() => {
        //Pongo el scroll arriba
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return null;
};

export default ScrollToTop;