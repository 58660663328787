import { combineReducers } from 'redux';
import categoriesReducer from './categoriesReducer';
import productsReducer from './productsReducer';
import brandsReducer from './brandsReducer';
import userReducer from './userReducer';
import freeShippingLimitReducer from './freeShippingLimitReducer';

export default combineReducers({
    categories: categoriesReducer,
    products: productsReducer,
    brands: brandsReducer,
    user: userReducer,
    freeShipping: freeShippingLimitReducer
});