import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Footer from '../layout/Footer';
import Breadcrumb from '../layout/Breadcrumb';
import Header from '../layout/Header';

import { Alert } from 'react-bootstrap';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, showRegistrationAction } from '../../actions/userActions';
//Cookies
import Cookie from '../../services/CookieService';
import { COOKIE_ACCESS_TOKEN } from '../../config/cookies';
//Función principal
const LoginRegister = () => {
    //History
    const history = useHistory();
    //Genero los ref
    const refEmail = useRef();
    const refPassword = useRef();
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Traigo el store
    const authLoading = useSelector( state => state.user.loading );
    const authError = useSelector( state => state.user.error );
    const authUser = useSelector( state => state.user.user );
    //Inicializo el state local
    const [ user, setUser ] = useState({
        email: '',
        password: ''
    })
    //Traigo el token
    const token = Cookie.get(COOKIE_ACCESS_TOKEN);
    //Me fijo si hay datos
    if(authUser || token){
        history.push('/');
    }
    //Manejo los cambios
    const handleChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    //Manejo el submit
    const handleSubmit = e => {
        e.preventDefault();
        //Debo verificar los datos
        if(!user.email || user.email.trim() === ''){
            refEmail.current.focus();
            return false;
        }
        if(!user.password || user.password.trim().length < 4){
            refPassword.current.focus();
            return false;
        }
        //Ejecuto el login
        dispatch( loginAction(user) );
        //Una vez finalizado, reinicio
        setUser({
            email: '',
            password: ''
        })
    }
    //Manejo la registración
    const handleRegistration = () => {
        //Ejecuto la carga
        dispatch( showRegistrationAction(history) );
    }
    //Renderizo
    return (
        <React.Fragment>
            <Header />
            <Breadcrumb title="ingresar | registrarse" />
            <section className="login-page section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="title">Iniciar sesión</h3>
                            <div className="theme-card">
                                <form className="theme-form" onSubmit={handleSubmit} autoComplete="off">
                                    {
                                        authError ? (
                                            <Alert variant="danger">
                                                { authError.message }
                                            </Alert>
                                        ) : null
                                    }
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="email" 
                                            value={user.email}
                                            placeholder="Email" 
                                            onChange={handleChange}
                                            required 
                                            ref={refEmail} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="review">Contraseña</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            name="password" 
                                            value={user.password}
                                            placeholder="Ingrese su contraseña" 
                                            onChange={handleChange}
                                            required
                                            ref={refPassword} />
                                    </div>
                                    <button className="btn btn-solid" disabled={authLoading}>Ingresar</button>
                                    <Link className="ml-2" to="/recuperar-contrasena">
                                        Olvidó su contraseña?
                                    </Link>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 right-login">
                            <h3 className="title">Nuevo cliente</h3>
                            <div className="theme-card authentication-right">
                                <h6 className="title-font">Crear una cuenta</h6>
                                <p>Registre su cuenta de forma gratuita en nuestra tienda. La registración es rápida y fácil. Le permitirá realizar sus pedidos en nuestra tienda. Para comenzar a comprar haga click en registrarse.</p>
                                <button className="btn btn-solid" onClick={handleRegistration}>
                                    Crear una cuenta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};

export default LoginRegister;