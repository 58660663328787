import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import Footer from '../layout/Footer';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataAction, editUserAction } from '../../actions/userActions';
//Services
import { validateUser } from '../../services/UserService';
import Loader from '../extras/Loader';
//Función principal
const Profile = () => {
    //History
    const history = useHistory();
    //Creo los refs
    const refName = useRef();
    const refLastname = useRef();
    const refEmail = useRef();
    const refStreet = useRef();
    const refNumber = useRef();
    const refCity = useRef();
    const refState = useRef();
    const refZipcode = useRef();
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Inicializo el state local
    const [ user, setUser ] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street: '',
        number: '',
        floor: '',
        apartment: '',
        city: '',
        state: '',
        zipcode: ''
    })
    //Traigo el store
    const authLoading = useSelector( state => state.user.loading );
    const authUser = useSelector( state => state.user.user );
    //Traigo el token
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    //Me fijo si hay datos
    if(!authUser && !token){
        history.push('/iniciar-sesion');
    }
    //Al inicio cargo los datos
    useEffect(() => {
        dispatch( getUserDataAction(authUser) );
    }, [dispatch]);
    //Veo los cambios de usuario
    useEffect(() => {
        if(authUser){
            setUser({
                first_name: authUser.account.first_name,
                last_name: authUser.account.last_name,
                email: authUser.account.email,
                phone: authUser.account.phone,
                street: authUser.account.street,
                number: authUser.account.number,
                floor: authUser.account.floor,
                apartment: authUser.account.apartment,
                city: authUser.account.city,
                state: authUser.account.state,
                zipcode: authUser.account.zipcode
            })
        }
    }, [authUser]);
    //Manejo los cambios
    const onChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    //Manejo elsubmit
    const handleSubmit = e => {
        e.preventDefault();

        const validation = validateUser(user);
        //Verifico
        if(validation.success){
            dispatch( editUserAction(user) );
        }
    }
    //Renderizo
    return (
        <React.Fragment>
            {
                !authUser && authLoading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <Header />
                        <Breadcrumb title="Mi perfil" />
                        <section className="contact-page register-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="title pt-0">DATOS PERSONALES</h3>
                                        <form className="theme-form">
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label htmlhtmlFor="first_name">Nombres</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="first_name" 
                                                        placeholder="Nombre/s" 
                                                        required
                                                        value={user.first_name}
                                                        onChange={onChange}
                                                        ref={refName}
                                                        autoFocus />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlhtmlFor="last_name">Apellido</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="last_name" 
                                                        placeholder="Apellido" 
                                                        required
                                                        value={user.last_name}
                                                        onChange={onChange}
                                                        ref={refLastname} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlhtmlFor="phone">Teléfono</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"
                                                        name="phone" 
                                                        placeholder="Teléfono"
                                                        value={user.phone}
                                                        onChange={onChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlhtmlFor="email">Email</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Ingrese su email" 
                                                        name="email"
                                                        required
                                                        value={user.email}
                                                        onChange={onChange}
                                                        ref={refEmail} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="contact-page register-page section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="title pt-0">DIRECCIÓN DE ENVÍO</h3>
                                        <form className="theme-form" autoComplete="off" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label htmlFor="street">Calle *</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"  
                                                        name="street"
                                                        placeholder="Ingrese calle" 
                                                        required
                                                        value={user.street}
                                                        onChange={onChange}
                                                        ref={refStreet} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="number">Número *</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"  
                                                        name="number"
                                                        placeholder="Ingrese número" 
                                                        required
                                                        value={user.number}
                                                        onChange={onChange}
                                                        ref={refNumber} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="floor">Piso</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"  
                                                        name="floor"
                                                        placeholder="Ingrese piso" 
                                                        value={user.floor}
                                                        onChange={onChange}
                                                        />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="apartment">Depto.</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"  
                                                        name="apartment"
                                                        placeholder="Ingrese depto." 
                                                        value={user.apartment}
                                                        onChange={onChange}
                                                        />
                                                </div>
                                                <div className="col-md-5">
                                                    <label htmlFor="city">Ciudad *</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"
                                                        name="city" 
                                                        placeholder="Ciudad" 
                                                        required
                                                        value={user.city}
                                                        onChange={onChange}
                                                        ref={refCity} />
                                                </div>
                                                <div className="col-md-5 select_input">
                                                    <label htmlFor="state">Provincia *</label>
                                                    <select className="form-control" size="1" name="state" required value={user.state} onChange={onChange} ref={refState}>
                                                        <option value="Tucumán">Tucumán</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="zipcode">C.P. *</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"
                                                        name="zipcode"
                                                        placeholder="Cód. postal" 
                                                        required
                                                        value={user.zipcode}
                                                        onChange={onChange}
                                                        ref={refZipcode} />
                                                </div>
                                                
                                                <div className="col-md-12">
                                                    <button className="btn btn-sm btn-solid" type="submit">Actualizar perfil</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                )
            }
            <Footer />
        </React.Fragment>
    );
};

export default Profile;