import Cookies from 'universal-cookie';
import Store from 'store';
import { COOKIE_ACCESS_TOKEN } from '../config/cookies';

const cookie = new Cookies();

class CookieService {

    get(key){
        //return cookie.get(key) ? cookie.get(key) : Store.get('__' + key);
        return Store.get('__' + key) ? 
            (key === COOKIE_ACCESS_TOKEN ?  Store.get('__' + key) : JSON.parse(Store.get('__' + key)))
            : cookie.get(key);
    }

    set(key, value, options){
        //Seteo cookie
        cookie.set(key, value, {
            path: '/',
            domain: process.env.REACT_APP_DOMAIN,
            sameSite: true
        });
        //Seteo alternativo
        Store.set('__' + key, value);
    }

    remove(key){
        //Remuevo cookie
        cookie.remove(key, {
            path: '/',
            domain: process.env.REACT_APP_DOMAIN,
            sameSite: true
        });
        //Remuevo alternativo
        Store.remove('__' + key);
    }
}

export default new CookieService();