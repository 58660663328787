import React, { useState } from 'react';
import QuickView from './QuickView';
//Redux
import { useDispatch } from 'react-redux';
import { addWishlistAction, addToCartAction } from '../../actions/userActions';
import { Link } from 'react-router-dom';
//Función principal
const BoxMarket = props => {
    //Tomo los props
    const { product, boxSize } = props;
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Inicio el state local
    const [ showOverlay, setShowOverlay ] = useState(false);
    const [ showQuickView, setShowQuickView ] = useState(false);
    const [ qty, setQty ] = useState(1);
    //Manejo el overlay
    const handleOverlay = () => {
        setShowOverlay(!showOverlay);
    }
    //Manejo la vista rapida
    const handleQuickView = (e, show) => {
        if(typeof e !== 'undefined'){
            e.preventDefault();
        }
        //Oculto o muestro
        setShowQuickView(show);
    }
    //Manejo el cambio de valor
    const handleChange = e => {
        let v = e.target.value;
        if(isNaN(v) || v < 1){
            v = 1;
        }
        //Seteo el valor
        setQty(v);
    }
    //Manejo el add to wishlist
    const handleAddWishlist = (e, item) => {
        e.preventDefault();
        if(item){
            dispatch(addWishlistAction(item));
        }
    }
    //Agregar al carrito
    const handleAddtoCart = (e, item) => {
        e.preventDefault();
        //Genero los datos
        const data = {
            product_id: product.id,
            qty
        }
        //Hago el dispatch
        dispatch( addToCartAction(data) );
        //Una vez agregado, reinicio
        setQty(1);
        //Cierro el overlay
        setShowOverlay(!showOverlay);
    }
    //Renderizo
    return (
        <div className={`${boxSize} col-6 col-grid-box`}>
            <div className="product-box">
                <div className="img-block">
                    <a href="!#" onClick={e => handleQuickView(e, true)}>
                        {
                            product.images.length > 0 ? (
                                <img src={ product.images[0].img1 } className="img-fluid bg-img" alt="" />
                            ) : (
                                <img src="/assets/img/products/default_970.jpg" className="img-fluid bg-img" alt="" />
                            )
                        }
                    </a>
                    {
                        product.has_discount ? (
                            <div class="lable-wrapper">
                                <span class="lable3">Oferta</span>
                            </div>
                        ) : null
                    }
                    <div className="cart-details">
                        <button tabIndex="0" className="addcart-box" title="Compra rápida" onClick={handleOverlay}>
                            <i className="fa fa-shopping-cart"></i>
                        </button>
                        <a href="!#" title="Agregar a Favoritos" onClick={e => handleAddWishlist(e, product)}>
                            <i className="fa fa-heart" aria-hidden="true"></i>
                        </a>
                        <a href="!#" title="Vista rápida" onClick={e => handleQuickView(e, true)}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </a>

                        <QuickView 
                            show={showQuickView} 
                            handleClose={(e, show) => handleQuickView(e, show)} 
                            product={product}
                        />
                    </div>
                </div>
                <div className="product-info">
                    <div>
                        <Link to={`/mercadito/producto/detalle/${product.slug}`}>
                            <h6>
                                { product.name }
                                <br/>
                                <small>{ product.brand ? product.brand.name : 'Genérico' }</small>
                            </h6>
                        </Link>
                        <p>{ product.description }</p>
                        <h5>
                            $ {product.price}
                            {
                                product.has_discount ? (
                                    <>
                                    <small className="ml-1"><strike>${ product.original_price }</strike></small>
                                    </>
                                ) : null
                            }
                        </h5>
                    </div>
                </div>
                <div className={`addtocart_box ${showOverlay ? 'open' : null}`}>
                    <div className="addtocart_detail">
                        <div>
                            <div className="color">
                                <h5>{ product.name}</h5>
                            </div>
                            <div className="size">
                                <h5>Cantidad</h5>
                                <div className="input-group">
                                    <input 
                                        type="number" 
                                        name="qty" 
                                        className="form-control input-number" 
                                        value={qty} 
                                        autoComplete="off"
                                        onChange={handleChange}
                                        />
                                </div>
                            </div>
                            <div className="addtocart_btn">
                                <a href="!#" className="closeCartbox" tabIndex="0" onClick={e => handleAddtoCart(e, product)}>
                                    Agregar al carrito
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="close-cart" onClick={handleOverlay}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoxMarket;