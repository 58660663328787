import {
    LIST_CATEGORIES,
    LIST_CATEGORIES_SUCCESS,
    LIST_CATEGORIES_ERROR
} from '../types';

//Cada reducer tiene su propio state
const initialState = {
    categories: null,
    error: null,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type){
        case LIST_CATEGORIES:
            return {
                ...state,
                loading: true
            }
        case LIST_CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case LIST_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                categories: action.payload
            }
        default:
            return state;
    }
}