import React from 'react';

const FreeShippingAlert = ({ cart }) => {
    return (
        <>
        {
            cart && cart.items && cart.items.length > 0 && cart.free_shipping_remain < 0 ? (
                <div className="alert alert-success">
                    <i className="fa fa-info-circle"></i> Te falta <strong>${ Math.abs(cart.free_shipping_remain).toFixed(2) }</strong> para tener envío gratis.
                </div>
            ) : null
        }
        </>
    );
};

export default FreeShippingAlert;