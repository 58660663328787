import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';

const SliderHome = () => {
    const settings = {
        autoplay: false,
        autoplaySpeed: 5000
    }
    return (
        <div className="">
            <section className="p-0 full-slider shadow-home">
                <Slider { ...settings} className="slide-1 home-slider home-40">
                <div>
                    <div className="home p-left bg-size" style={{ backgroundImage: 'url("/assets/img/slider/slide-2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat:'no-repeat', display: 'block'  }}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="slider-contain">
                                        <div>
                                            <h1>Alimentos saludables</h1>
                                            {
                                                isMobile ? (
                                                    <h4 style={{ color: '#000000', marginTop: '10px', marginBottom: '10px'}}>
                                                        Siempre estamos a <br />tiempo de elegir <br />mejor cómo nutrirnos...
                                                    </h4>
                                                ) : (
                                                    <h4>Siempre estamos a tiempo de elegir mejor cómo nutrirnos...</h4>
                                                )
                                            }
                                            
                                            <Link to="/mercadito/productos" className="btn btn-solid">
                                                Entrá al Mercadito
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Slider>
            </section>
        </div>
    );
};

export default SliderHome;